import React, { Component } from 'react';
class Articles extends Component {
    constructor(props)
    {   
        super(props)
        this.state = {
            path:'',
            active_page:'1',
            previous_page1:null,
            previous_page2:null,
            next_page1:null,
            next_page2:null,
        }
    }
    
    change_page = (value) =>{
        var value_length = value.charAt(0);
        const count = Number(value_length)
        var page_no = count;
        this.setState({active_page:page_no})
        this.setState({previous_page1:page_no-1})
        this.setState({previous_page2:page_no-2})
        this.setState({next_page1:page_no+1})
        this.setState({next_page2:page_no+2})
    }

    change_page_first = (value) =>{
        var value_length = value.charAt(0);
        const count = Number(value_length)
        var page_no = count+1;
        this.setState({active_page:page_no})
        this.setState({previous_page1:page_no-1})
        this.setState({previous_page2:page_no-2})
        this.setState({next_page1:page_no+1})
        this.setState({next_page2:page_no+2})
    }

    render() {
        return (
        <div style={{marginLeft:"-15px"}}>
            <div className="col-md-12">
                <div className="container-fluid">
                <div className="article-header" style={{width:"100.7%"}}>
                    <h2>Latest Articles</h2>
                </div>
                <div className="article_body">
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2020-01%2Fglobal%2520%25281%2529.jpg" alt="Wall Street After A Long Holiday Weekend" title="Wall Street After A Long Holiday Weekend" style={{width: '288px', height: '100px'}} className="lazy article-image0" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-after-long-holiday-weekend" className="blog-heading"><span className="news-headings">Wall Street After A Long Holiday Weekend</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Arnab Das, Jan 22, 2020 7:14 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">Sentiment in the stock market was downbeat as investors returned from a long holiday weekend to head...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-after-long-holiday-weekend">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2020-01%2Fstock-22.jpg" alt="Monthly Market Update And The End Of The Decade" title="Monthly Market Update And The End Of The Decade" style={{width: '288px', height: '100px'}} className="lazy article-image1" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/market-update-last-month-decade" className="blog-heading"><span className="news-headings">Market Update For The Last Month Of The Decade</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Moumita Dey, Jan 03, 2020 7:00 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">The November jobs report was better than expected as growth soars. The unemployment rate was reduced...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/market-update-last-month-decade">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-12%2Fsp400-tmc.jpg" alt="Unsolved Trade Deal & Future of U.S. Wireless Industry" title="Unsolved Trade Deal & Future of U.S. Wireless Industry" style={{width: '288px', height: '100px'}} className="lazy article-image2" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/unsolved-trade-deal-future-us-wireless-industry" className="blog-heading"><span className="news-headings">Unsolved Trade Deal &amp; Future of U.S. Wireless Industry</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Manveena Agrawal, Dec 10, 2019 7:00 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">Stocks are off to a mixed open, coinciding with no reported progress on U.S.-China trade talks befor...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/unsolved-trade-deal-future-us-wireless-industry">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-11%2Fglobmkt-du.jpg%2520%25281%2529.jpg" alt="Wall Street Ahead On Thursday's Trading Session." title="Wall Street Ahead On Thursday's Trading Session." style={{width: '288px', height: '100px'}} className="lazy article-image3" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-ahead-thursdays-trading-session" className="blog-heading"><span className="news-headings">Wall Street Ahead On Thursday's Trading Session.</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Arnab Das, Nov 21, 2019 7:00 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">Stocks turned firmly into the red on renewed trade deal concerns, the latest being a Reuters report ...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-ahead-thursdays-trading-session">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-11%2Fnasdaq100-wqp.jpg" alt="" style={{width: '288px', height: '100px'}} className="lazy article-image4" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/another-resilient-session-despite-losses-investors-moved-buy-dip-pare-markets-early-drop" className="blog-heading"><span className="news-headings">Another Resilient Session Despite The Losses, As Investors Moved In To Buy The Dip To Pare The market's early drop.</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Ankur Ghosh, Nov 12, 2019 6:54 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">Stocks mostly edged lower in a quiet session but gains in Boeing and Walgreens Boots Alliance helped...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/another-resilient-session-despite-losses-investors-moved-buy-dip-pare-markets-early-drop">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                            <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-11%2Fnasdaq100-hys.jpg" alt="The Most Active Stock On Thursday" title="The Most Active Stock On Thursday" style={{width: '288px', height: '100px'}} className="lazy article-image5" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                                <a target="_self" href="https://www.marketanalyst.ai/article/most-active-stock-thursday" className="blog-heading"><span className="news-headings">The Most Active Stock On Thursday</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Arnab Das, Nov 08, 2019 5:37 PM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">The Dow and S&amp;P 500 closed at fresh records Thursday even after reports emerged of “fierce int...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/most-active-stock-thursday">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                            <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-10%2Fstock-21.jpg" alt="October Indicating Mixed Sentiments" title="October Indicating Mixed Sentiments" style={{width: '288px', height: '100px'}} className="lazy article-image6" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                                <a target="_self" href="https://www.marketanalyst.ai/article/october-indicating-mixed-sentiments" className="blog-heading"><span className="news-headings">October Indicating Mixed Sentiments</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Ankur Ghosh, Oct 30, 2019 6:45 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">In the current situation, the market is getting a bit difficult to judge. Last year, the SP500 index...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/october-indicating-mixed-sentiments">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                            <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-10%2Fglobmkt-du.jpg.jpg" alt="Heaviest Day Of Third-Quarter Earnings Reports" title="Heaviest Day Of Third-Quarter Earnings Reports" style={{width: '288px', height: '100px'}} className="lazy article-image7" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/heaviest-day-third-quarter-earnings-reports" className="blog-heading"><span className="news-headings">Heaviest Day Of Third-Quarter Earnings Reports</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Sramana Sengupta, Oct 25, 2019 6:28 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">3M rains down on Microsoft’s parade. strong quarterly results from Microsoft pushed the S&amp;P 50...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/heaviest-day-third-quarter-earnings-reports">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-10%2Fnasdaq100-wyp.jpg" alt="Wall Street Before The Weekend" title="Wall Street Before The Weekend" style={{width: '288px', height: '100px'}} className="lazy article-image8" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-weekend" className="blog-heading"><span className="news-headings">Wall Street Before The Weekend</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Sramana Sengupta, Oct 17, 2019 1:30 AM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">Wall Street finished with little loss on Wednesday due to the weak retail sales data along with conc...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-weekend">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="mb-3 container-fluid content-articles-box">
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xs-7 articles-content-img-box">
                        <img src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%3A9001%2Fsites%2Fdefault%2Ffiles%2F2019-10%2Fstock-20.jpg" alt="Wall Street Set To Open In The Red Amid China Trade Talk Uncertainty" title="Wall Street Set To Open In The Red Amid China Trade Talk Uncertainty" style={{width: '288px', height: '100px'}} className="lazy article-image9" />                               
                        </div>    
                        <div className="col-sm-6 col-md-6 col-xs-5 space-reduce mobile-space-reduce">
                        <div className="articles-header">
                            <span className="news-title ">
                            <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-set-open-red-amid-china-trade-talk-uncertainty" className="blog-heading"><span className="news-headings">Wall Street Set To Open In The Red Amid China Trade Talk Uncertainty</span></a>
                            </span>
                            <span className="blog-author">
                            <i className="fa fa-user-o" aria-hidden="true" />Ankur Ghosh, Oct 10, 2019 4:30 PM GMT			</span>
                        </div>
                        <div className="blogs-content">
                            <span className="blog-desc">U.S. stock index futures were slightly lower Thursday morning, as investors closely monitor the stat...</span>
                        </div>
                        <div className="blog-read-more pad-top-5 pad-top-5">
                            <i className="fa fa-angle-right" aria-hidden="true" /> <a target="_self" href="https://www.marketanalyst.ai/article/wall-street-set-open-red-amid-china-trade-talk-uncertainty">Read More</a>
                        </div>
                        </div>
                    </div>
                    </div>
                    {
                        this.state.active_page === '1' ?
                            <div>
                                <ul className="paginations"><li className="selected" >{this.state.active_page}</li>
                                <li className="pags" onClick={()=>this.change_page_first('10')}><a>2</a></li>
                                <li className="pags" onClick={()=> this.change_page_first('20')}><a>3</a></li>
                                <li className="pags" onClick={()=> this.change_page_first('10')}><a rel="next">&gt;</a></li>
                                <li className="pags"><a data-ci-pagination-page={18}>Last</a></li>
                                </ul> 
                            </div>
                            :

                            this.state.active_page === '2' ?
                            <div>
                                <ul className="paginations">
                                    <li className="pags"><a onClick={()=>this.change_page_first('')} data-ci-pagination-page={1} rel="prev">&lt;</a></li>
                                    <li className="pags"><a onClick={()=>this.change_page_first('')} data-ci-pagination-page={1} rel="start">1</a></li>
                                    <li className="selected">2</li>
                                    <li className="pags"><a onClick={()=>this.change_page_first('20')} data-ci-pagination-page={3}>3</a></li>
                                    <li className="pags"><a onClick={()=>this.change_page_first('30')} data-ci-pagination-page={4}>4</a></li>
                                    <li className="pags"><a onClick={()=>this.change_page_first('20')} data-ci-pagination-page={3} rel="next">&gt;</a></li>
                                    <li className="pags"><a  data-ci-pagination-page={18}>Last</a></li>
                                </ul>
                            </div>
                            :
                            <ul className="paginations" style={{display:'inline-flex'}}>
                                <li className="pags"><a onClick={()=>this.change_page(this.state.previous_page1+'0')} data-ci-pagination-page={this.state.previous_page1} rel="prev">&lt;</a></li>
                                {
                                    this.state.previous_page2 > 0 && this.state.previous_page1 > 0 ?
                                        <div>
                                        <li className="pags"><a onClick={()=>this.change_page(this.state.previous_page2+'0')} data-ci-pagination-page={this.state.previous_page2} rel="start">{this.state.previous_page2}</a></li>
                                        <li className="pags"><a onClick={()=>this.change_page(this.state.previous_page1+'0')}  data-ci-pagination-page={this.state.previous_page1}>{this.state.previous_page1}</a></li>
                                        </div>
                                    :null
                                }
                                
                                <li className="selected">{this.state.active_page}</li>
                                <li className="pags"><a onClick={()=>this.change_page(this.state.next_page1+'0')} data-ci-pagination-page={this.state.next_page1}>{this.state.next_page1}</a></li>
                                <li className="pags"><a onClick={()=>this.change_page(this.state.next_page2+'0')} data-ci-pagination-page={this.state.next_page2}>{this.state.next_page2}</a></li>
                                <li className="pags"><a onClick={()=>this.change_page(this.state.next_page1+'0')} data-ci-pagination-page={this.state.next_page1} rel="next">&gt;</a></li>
                                <li className="pags"><a  data-ci-pagination-page={18}>Last</a></li>
                            </ul>
                    }
                    </div>
                </div>
            </div>
            {/* </Router> */}
        </div>
        );
    }
}

export default Articles ;
