import React, { Component } from 'react';
class TermUse extends Component {
    constructor(props)
    {   
        super(props)
        this.state = {
           
        }
    }
    
    render() {
        return (
            <div className="container privacy-terms" style={{marginTop:"52px"}}>
            <div className="page-header cust-home">
                <h1 style={{color:"#444444"}}>Privacy</h1>
            </div>
            <div className="row">
                <div className="col-md-12 terms">
                <p>
                    <span style={{ fontSize: "14px" }}>
                    Agrud Technologies Pte Ltd. respects your right to privacy. This
                    Privacy Policy sets out details of the information that we may collect
                    from you and how we may use that information. This Privacy Policy
                    should be read alongside, and in addition to, our website
                    <a href="https://www.marketanalyst.ai/terms">
                        {" "}
                        General Terms and Conditions
                    </a>
                    &nbsp;and our{" "}
                    <a href="https://www.marketanalyst.ai/service">
                        Terms and Conditions of Service
                    </a>
                    . Please read this Privacy Policy carefully – by using our website
                    platform or services (the “Platform“) or purchasing any of our
                    products/services, you agree to its terms (including as amended from
                    time to time). If, for any reason, you do not agree to the terms of
                    this Privacy Policy, please stop using the Platform immediately.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>Who are we?</span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    In this Privacy Policy, references to “we” or “us” are to Agrud
                    Technologies Pte Ltd., organised and existing under the laws of
                    Singapore whose principal place of business is at 300 Tampines Ave 5,
                    Tampines Junction, Level 09­-02, Singapore 529653.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    If you have any questions about how we collect, store and use your
                    information, you may contact us by telephoning/e-mailing us at
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>Telephone : +65 67359465</span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>E-mail: admin@agrud.com</span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>The legal stuff</span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    We will at all times only collect and process your personal
                    information in accordance with the applicable data protection
                    legislation.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>
                        What information do we collect and how do we collect it?
                    </span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    When you use the Platform or contact us by post, telephone, fax,
                    e-mail or SMS, or any other method we may collect, store and use
                    certain personal information that you disclose to us. The information
                    that we may collect from you includes your name, address, telephone,
                    fax, e-mail and mobile contact details, details of any purchases that
                    you make through the Platform and your credit or debit card details.
                    We may also collect details of your computer’s internet protocol
                    address, which is automatically collected by our web server. Finally,
                    we may receive information about you from third parties (such as
                    credit card payment processing companies) who are legally entitled to
                    disclose that information.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>
                        How do we use your information?
                    </span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    By using the Platform or purchasing any services through the Platform,
                    you agree that your information may be used by us and by carefully
                    selected third parties chosen by us for any of the following purposes:
                    (a) to provide you with a personalised browsing experience when using
                    the Platform; (b) to fulfil any contractual agreements between you and
                    us (for example, when you place an order for products/services through
                    the Platform); (c) to send you details (including by post, phone, fax,
                    e-mail or SMS) of other products and services which we think may
                    interest you, unless you inform us in writing that you do not want to
                    receive these details; (d) to manage any account that you hold with
                    us; and (e) to comply with legal and regulatory requirements.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    We may also disclose your information to third parties in connection
                    with the sale, transfer or disposal of our business, provided that
                    they continue to use your information substantially in accordance with
                    the terms of this Privacy Policy.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    We have put in place technical and organisational security measures to
                    prevent the loss or unauthorised access of your personal information.
                    However, whilst we have used our best efforts to ensure the security
                    of your data, please be aware that we cannot guarantee the security of
                    information transmitted over the Internet.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>What are your rights?</span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    You can contact us in order to find out what information we hold about
                    you (please note that we may charge an administrative fee for such
                    enquiries) or to access, cancel or correct any information that we
                    hold about you.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    You may also contact us in writing to inform us if you do not wish to
                    receive any marketing materials from us.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>What are cookies?</span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    The Platform uses cookies. Cookies are small files stored on your
                    computer’s hard drive which are used to collect your personal
                    information. You may choose to refuse cookies but, if you do so, some
                    of the functionality of the Platform may no longer be available to
                    you.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    For more information about cookies, including further details as to
                    what they are and how to refuse them, please visit
                    <a href="http://www.allaboutcookies.org"> www.allaboutcookies.org</a>.
                    </span>
                </p>
                <p>
                    <strong>
                    <span style={{ fontSize: "14px" }}>
                        How up-to-date is this Privacy Policy?
                    </span>
                    </strong>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    We may update or amend this Privacy Policy from time to time to comply
                    with law or to meet our changing business requirements without notice
                    to you. Any updates or amendments will be posted on the Platform. By
                    continuing to use the Platform or buying any services from us, you
                    agree to be bound by the terms of these updates and amendments.
                    </span>
                </p>
                <p>
                    <span style={{ fontSize: "14px" }}>
                    This Privacy Policy was last updated on October 2019.
                    </span>
                </p>
                </div>
            </div>
            </div>
        );
    }
}

export default TermUse ;
