import React, { Component } from 'react';
import Daily from './Dailychart'
class Charts extends Component {
    render() {
      return (
        <div>
            <div className="col-md-12">
                <div className="markets-middle" >
                <div title="Click to open AMEX:SIF- Snapshots page" className="header-banner" data-href="https://www.marketanalyst.ai/markets/amex-sif/snapshots" data-target="_self">
                    <div className="container_security_header">
                    <div className="security_header_content">
                        <div className="border-neutral amex-sif-panel-border">
                        </div>
                        <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xl-3 newscontent_left_panel pl-0">
                            <div className="col-sm-12 w-150 float_left screener_header_name equity-name ml-2" data-uri="amex-sif" title="SIFCO Industries Inc">SIFCO Industries Inc</div>
                            <div className="col-sm-12 w-150 float_left screener_header_ticker ml-2 ">AMEX:SIF</div>
                            <div className="col-sm-12 w-150 float_left market_close_time ml-2 float-left amex-sif-close ">Market Close 07/08</div>
                            </div>
                            <div className="col-md-4 col-xl-4 newscontent_middle_panel pl-4 ">
                            <span className="header_price amex-sif-last-price">4.00</span>
                            <span className="fund-tablink-price-currency">USD </span>
                            <span className="d-block neutral-percent-text-fill price-neutral font-weight-bold text-left pl-5 amex-sif-panel ">-</span>    		  </div>
                            <div className="col-md-5 col-xl-5 newscontent_right_panel">
                            <div className="row container_openprice">
                                <div className="col-md-5 col-xl-4 container_openprice_left_txt">OPEN:</div>
                                <div className="col-md-7 col-xl-8 container_openprice_right_price_value amex-sif-open">4.05</div>
                            </div>
                            <div className="row container_openprice">
                                <div className="col-md-5 col-xl-5 container_openprice_left_txt">DAY RANGE:</div>
                                <div className="col-md-7 col-xl-7 container_openprice_right_price_value amex-sif-range">- - -</div>
                            </div>
                            <div className="row different_price_range market-close ">
                                <div className="col-md-2 pe_pb_ps_text">PE:</div>
                                <div className="col-md-2 pe_pb_ps_value font_red">-1.27</div>
                                <div className="col-md-2 pe_pb_ps_text">PB:</div>
                                <div className="col-md-2 pe_pb_ps_value font_green">0.65</div>
                                <div className="col-md-2 pe_pb_ps_text">PS:</div>
                                <div className="col-md-2 pe_pb_ps_value font_green">0.14</div>
                            </div>
                            </div>
                        </div>
                        <div className="row container_wtd_mtd_qtd_ytd market-close ">
                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">WTD % <span className="text-white">-</span>
                            </div>
                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">MTD % <span className="text-white">-</span>
                            </div>
                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">QTD % <span className="text-white">-</span>
                            </div>
                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">YTD % <span className="text-white">-</span>
                            </div>
                        </div>
                        <div className="row market-close-highlight market-close ">
                            <div className="float-left ml-4">* Updated at market close</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="nav-pills-container" id="charts-pills">
                    <ul className="nav nav-pills mobile-view-scroling-container-nav-pills">
                    <li className="nav-item" id="snapshots">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif/snapshots">Snapshots</a>
                    </li>
                    <li className="nav-item" id="news">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif">News</a>
                    </li>
                    <li className="nav-item" id="stats">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif/analytics">Analytics</a>
                    </li>
                    <li className="nav-item" id="comp">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif/competitors">Competitors</a>
                    </li>
                    <li className="nav-item" id="fin">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif/financials">Financials</a>
                    </li>
                    <li className="nav-item" id="chart">
                        <a className="nav-link" href="https://www.marketanalyst.ai/markets/amex-sif/chart">Chart</a>
                    </li>
                    </ul>
                </div>
                </div>
                <br />
                <br />
                <div className="col-sm-12">
                <ul className="nav nav-tabs" id="charts-tabs" role="tablist">
                    <li className="nav-item" style={{cursor: 'pointer'}}>
                    <a className="nav-link active" id="daily-tab"  data-toggle="tab" href="#daily" role="tab" aria-controls="daily" aria-selected="false">Daily</a>
                    </li>
                    <li className="nav-item" style={{cursor: 'pointer'}}>
                    <a className="nav-link " id="hourly-tab"  data-toggle="tab" href="#hourly" role="tab" aria-controls="hourly" aria-selected="false">Hourly</a>
                    </li>
                    <li className="nav-item" style={{cursor: 'pointer'}}>
                    <a className="nav-link " id="weekly-tab"  data-toggle="tab" href="#weekly" role="tab" aria-controls="weekly" aria-selected="false">Weekly</a>
                    </li>
                </ul>
                </div>
                <div >
                <div className="row">
                    <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily">
                        <Daily />
                    </div>
                    <div className="tab-pane fade " id="hourly" role="tabpanel" aria-labelledby="hourly" />
                    <div className="tab-pane fade " id="weekly" role="tabpanel" aria-labelledby="weekly" />
                    </div>
                </div>
                </div>
            </div>
        </div>
      );
    }
}

export default Charts;
