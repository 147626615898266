import React, { Component } from 'react';

class Careers extends Component {
    render() {
        return (
        <div>
            <div className="container content-container text-center" id="career">
            <div className="row">
            <div className="col-md-12">
                <h2>Life &amp; Careers</h2>   
                <div className="carousel-containers">
                <div className="carousel-wrapper">
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">  
                        <li data-target="#carouselExampleIndicators" data-slide-to={0} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} className="active" />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol> 
                    <div className="carousel-inner" role="listbox">                            
                        <div className="carousel-item">
                        <img alt="" className="d-block img-fluid" src="https://www.marketanalyst.ai/assets/common/images/life-career2.jpg" />
                        </div>
                        <div className="carousel-item active">
                        <img alt="" className="d-block img-fluid" src="https://www.marketanalyst.ai/assets/common/images/life-career1.jpg" />                              
                        </div>  
                        <div className="carousel-item">
                        <img alt="" className="d-block img-fluid" src="https://www.marketanalyst.ai/assets/common/images/life-career3.jpg" />                              
                        </div> 
                        <a className="carousel-control-prev career-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <i className="fa fa-angle-left fa-3x" />
                        <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next career-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                        <i className="fa fa-angle-right fa-3x" />
                        <span className="sr-only">Next</span>
                        </a>
                    </div>
                    </div>
                </div>
                </div>
                <div className="first left-faq"> <p>We are hard-working, blue-collar artists, craftsmen from our hearts. While most of us are established professionals, few are a starter but with great talent, passion and energy. Coders &amp; Designers. Marketers &amp; Analysts. Fathers and Mothers. Family and Friends!</p>
                <h5><span style={{fontSize: '20px'}}><strong>Want to Join our Stellar Team?</strong></span></h5>
                <p>We are a passionate and collaborative group working in a fast paced, creative startup environment with office in Kolkata and Singapore. Come join us. Send resume to <strong>hr@agrud.com.</strong></p>
                </div>
                <div className="opening-buttons pb-5">
                <button type="button" className="btn btn-info blue-btn career-btn" data-toggle="modal" data-target="#researchanalystModal">
                    Research Analyst                      </button>
                {/*Modal*/} 
                <div className="modal fade" id="researchanalystModal" tabIndex={-1} role="dialog" aria-labelledby="researchanalystModalLabel" aria-hidden="true">
                    <div className="modal-dialog marg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title" id="researchanalystModalLabel">Research Associates for Fintech startup</h4>
                        </div>
                        <div className="modal-body">
                        <ul>
                            <li>Conversant with search engines and search techniques ( Understanding of Latest Semantic Indexing &amp; boolean search).</li>
                            <li>Self starter who can work on creation of search terms.</li>
                            <li>Knack for improving quality by regular checks and optimisation both input &amp; output (on website, emails, social media sites, apps etc).</li>
                            <li>Ensure the completed records align with business procedures and corporate policy requirements of Agrud.</li>
                            <li>Interact proactively with managers, when needed, to ensure the completeness.</li>
                            <li>Collate and review information from internal and external sources (Company website, Regulatory websites, Google, etc.).</li>
                            <li>Validate the information within our admin panel to ensure complete and accurate updates.</li>
                            <li>Complete checklist to demonstrate work carried out during the process.</li>
                            <li>Compile regular reporting to advise management on trends and issues.</li>
                            <li>Meet established daily targets for Agrud review and completion within timeframes.</li>
                            <li>Update workflow database regularly throughout the day.</li>
                            <li>Highlight any discrepancies to Management.</li>
                            <li>Escalate any negative news and high risk cases.</li>
                            <li>Provide periodic feedback.</li>
                            <li><strong>Basic Knowledge of Finance / Capital Markets is must.</strong></li>
                            <li>Shouldn’t have problems hanging around skype &amp; google docs.</li>
                        </ul>
                        </div>
                        <div className="modal-footer" />
                    </div>
                    </div>
                </div>
                </div>
            </div>   
            </div>
        </div>
        </div>
        );
    }
}

export default Careers;
