import React, { Component } from 'react';
import axios from 'axios';

class Us_equities extends Component {
    constructor(props)
    {   
        super(props)
        this.state = {
          base_url:'http://35.184.152.222:9999/'
        }
    }

    componentDidMount()
    {
      // axios.get(this.state.base_url+"", { 
      // })
      // .then(res => { 
       
      // })
    }

    render() {
      return (
        <div style={{marginLeft: "-15px"}}>
            <div className="col-lg-12 pr-0 markets-middle">
            <div className="row pb-3" style={{marginTop:"-4px"}}>
              <div className="created-by analytics-share big-cat ml-3 mt-2">
                <span className="news-share-facebook action-btns-utils analytics-fb" >
                  <i className="fa fa-facebook" aria-hidden="true" style={{width:"11px"}} />
                </span>
                <span className="news-share-twitter action-btns-utils analytics-twitter" >
                  <i className="fa fa-twitter" aria-hidden="true" />
                </span>
              </div>
            </div>	<div className="row">
              <div className="col-sm-12 custom-accordion ml-0" id="analytics-accordian" >
                {/* This will run in a loop Start*/}
                <div className="card" id="most-active-stocks-group" >
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse0" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Most Active Stocks<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                  <div id="collapse0" className="collapse in" aria-expanded="true" style={{}}>
                    <div className="card-body">
                      <section className="analytics_items_container_panel" id="most-active-stocks-panel">
                        <div id="most-active-stocks">
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 103 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/most-active-stocks" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>N</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/nasdaq-most-active" className="news-heading">
                                    <span className="news-headings">NASDAQ - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active - NASDAQ 100</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-information-technology-most-active" className="news-heading">
                                    <span className="news-headings">Mid Cap - Information Technology - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-information-technology-most-active" className="news-heading">
                                    <span className="news-headings">Large Cap - Information Technology - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-information-technology-most-active" className="news-heading">
                                    <span className="news-headings">Small Cap - Information Technology - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-most-active" className="news-heading">
                                    <span className="news-headings">Mid Cap - Financials - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="card" id="analytics-on-financials-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse1" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse1" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Financials Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                  <div id="collapse1" className="collapse in" aria-expanded="true" style={{}}>
                    <div className="card-body">
                      <section className="analytics_items_container_panel" id="analytics-on-financials-sector-panel">
                        <div id="analytics-on-financials-sector">
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 51 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-financials-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-most-active" className="news-heading">
                                    <span className="news-headings">Mid Cap - Financials - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-financials-most-active" className="news-heading">
                                    <span className="news-headings">Large Cap - Financials - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-financials-most-active" className="news-heading">
                                    <span className="news-headings">Small Cap - Financials - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-best-performers" className="news-heading">
                                    <span className="news-headings">Mid Cap - Financials - Best Performers</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Yesterday's Best Performing stocks in Financials - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-daily-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Daily Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Financials - Yesterday's Top performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-daily-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Daily Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Financials - Yesterday's Top performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-daily-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Daily Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Mid Cap Financials- Yesterday's Worst performers - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-daily-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Daily Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Financials - Yesterday's Worst performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-daily-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Daily Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Financials - Yesterday's Worst performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Mid Cap - Financials - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-financials-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Large Cap - Financials - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-financials-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Small Cap - Financials - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-weekly-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Weekly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-monthly-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Monthly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-quarterly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Quarterly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-quarterly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Quarterly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-quarterly-performers-financials" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Quarterly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-quarterly-performers-financials" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Quarterly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Financials - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-quarterly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Quarterly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-yearly-performers-financials" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Yearly Performers - Financials</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Year to Date performers Financials - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 51 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-financials-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="card" id="analytics-on-consumer-discretionary-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse2" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse2" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Consumer Discretionary Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                  <div id="collapse2" className="collapse in" aria-expanded="true" style={{}}>
                    <div className="card-body">
                      <section className="analytics_items_container_panel" id="analytics-on-consumer-discretionary-sector-panel">
                        <div id="analytics-on-consumer-discretionary-sector">
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 51 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-consumer-discretionary-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-consumer-discretionary-52-week-low" className="news-heading">
                                    <span className="news-headings">Large Cap - Consumer Discretionary - 52 Week Low</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Stocks hitting 52 week low in Consumer Discretionary - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-consumer-discretionary-52-week-low" className="news-heading">
                                    <span className="news-headings">Small Cap - Consumer Discretionary - 52 Week Low</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Stocks hitting 52 week low in Consumer Discretionary - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 51 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-consumer-discretionary-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="card" id="analytics-on-health-care-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse3" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse3" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Health Care Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                  <div id="collapse3" className="collapse in" aria-expanded="true" style={{}}>
                    <div className="card-body">
                      <section className="analytics_items_container_panel" id="analytics-on-health-care-sector-panel">
                        <div id="analytics-on-health-care-sector">
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 50 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-health-care-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-health-most-active" className="news-heading">
                                    <span className="news-headings">Mid Cap - Health - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-health-most-active" className="news-heading">
                                    <span className="news-headings">Large Cap - Health - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-health-most-active" className="news-heading">
                                    <span className="news-headings">Small Cap - Health - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-health-best-performers" className="news-heading">
                                    <span className="news-headings">Mid Cap - Health - Best Performers</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Yesterday's Best Performing stocks in Health - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-daily-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Daily Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Healthcare - Yesterday's Top performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-daily-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Daily Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Healthcare - Yesterday's Top performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-daily-performers-health" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Daily Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Mid Cap Healthcare- Yesterday's Worst performers - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-daily-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Daily Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Healthcare - Yesterday's Worst performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-daily-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Daily Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Health - Yesterday's Worst performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-health-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Mid Cap - Health - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Health - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-health-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Large Cap - Health - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Health - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-health-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Small Cap - Health - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Health - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Healthcare - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Health - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Health - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-weekly-performers-health" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Weekly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Healthcare - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Healthcare - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Health - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Health - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-monthly-performers-health" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Monthly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Healthcare - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-quarterly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Quarterly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-quarterly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Quarterly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Healthcare - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-health-qtd-best-performers" className="news-heading">
                                    <span className="news-headings">Mid Cap - Health - QTD Best Performers</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Best Performing stocks of {'{'}qtr{'}'} in Health - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-quarterly-performers-health" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Quarterly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Health - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-quarterly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Quarterly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Health - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-yearly-performers-health" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Yearly Performers - Health</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Year to Date performers Health - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 50 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-health-care-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="card" id="analytics-on-energy-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse4" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse4" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Energy Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                  <div id="collapse4" className="collapse in" aria-expanded="true" style={{}}>
                    <div className="card-body">
                      <section className="analytics_items_container_panel" id="analytics-on-energy-sector-panel">
                        <div id="analytics-on-energy-sector">
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 50 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-energy-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-energy-most-active" className="news-heading">
                                    <span className="news-headings">Mid Cap - Energy - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-energy-most-active" className="news-heading">
                                    <span className="news-headings">Large Cap - Energy - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-energy-most-active" className="news-heading">
                                    <span className="news-headings">Small Cap - Energy - Most Active</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-energy-best-performers" className="news-heading">
                                    <span className="news-headings">Mid Cap - Energy - Best Performers</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Yesterday's Best Performing stocks in Energy - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-daily-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Daily Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Energy - Yesterday's Top performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-daily-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Daily Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Energy - Yesterday's Top performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-daily-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Daily Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Mid Cap Energy - Yesterday's Worst performers - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-daily-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Daily Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Energy - Yesterday's Worst performers - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-daily-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Daily Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Energy - Yesterday's Worst performers - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-energy-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Mid Cap - Energy - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Energy - SP400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-energy-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Large Cap - Energy - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Energy - RUSSELL1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-energy-highest-dividend-yield" className="news-heading">
                                    <span className="news-headings">Small Cap - Energy - Highest Dividend Yield</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Energy - RUSSELL2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Energy - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-weekly-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Weekly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Energy - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Energy - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-monthly-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Worst Monthly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Energy - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-quarterly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Best Quarterly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-quarterly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Best Quarterly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-quarterly-performers-energy" className="news-heading">
                                    <span className="news-headings">Mid Cap - Best Quarterly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Energy - S&amp;P 400</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-quarterly-performers-energy" className="news-heading">
                                    <span className="news-headings">Large Cap - Worst Quarterly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Energy - RUSSELL 1000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-quarterly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Quarterly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-analytics-box">
                            <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                              <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>S</div>
                            </div>
                            <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                              <div className="news-header">
                                <span className="title-link">
                                  <span className="news-source left-one-text">[ agrud.com ]</span>
                                </span>
                                <span className="news-title content-newsbox-heading">
                                  <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-yearly-performers-energy" className="news-heading">
                                    <span className="news-headings">Small Cap - Worst Yearly Performers - Energy</span>
                                    <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                  </a>
                                </span>
                              </div>
                              <div className="news-content">
                                <div className="news-summary-wrapper">
                                  <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Year to Date performers Energy - RUSSELL 2000</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="analytics-count"> 
                            <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                            Showing 30 of 50 results. 
                            Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-energy-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
                <div className="card" id="analytics-on-industrials-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse5" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse5" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Industrials Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                </div>
                <div className="card" id="analytics-on-consumer-staples-sector-group">
                  <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse6" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                    <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse6" aria-expanded="true" aria-controls="collapse0">
                      <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                        Analytics on Consumer Staples Sector<i className="fa fa-angle-down rotate-icon" />
                      </h5>
                    </a>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      );
    }
}

export default Us_equities;
