import React, { Component } from 'react';
class Charts extends Component {
    componentDidMount () {
      const script = document.createElement("script");
      script.src = "http://34.67.106.166:3000/himanshu_chart.js";
      script.async = true;
      script.onload = () => this.scriptLoaded();
    
      document.body.appendChild(script);
    }
    render() {
      
      return (
        <div id="charts" className="tab-pane fade sp_charts active show">
        <section className="analytics-details news-block-container">
        <div className="scratchpad-portfolio-header">
          <h1>My Charts</h1>
        </div>
        <span className="my-change-wrapper d-none">
          <span title="Based on average calculation of daily price change" className="change_avg">
            <i className="fa fa-calculator" aria-hidden="true" />
          </span>
          <span title="Based on average calculation of daily price change" className="change_avg_price avg_red_color" />                
        </span>
        <div className="mb-2 scratchpad-border d-none" />
        <div className="mt-4 empty-scratchpad">Empty Charts</div>
      </section>
      </div>
      );
    }
}

export default Charts;
