import React, { Component } from 'react';

class About_agrud extends Component {
    render() {
        return (
            <div>
                <div className="container content-container" id="vision">
                    <div className="row">
                        <div className="col-xs-12 vision" style={{marginLeft:"-16px"}}>
                            <h2 style={{marginLeft: "-12px"}}>About Agrud </h2>
                            <p style={{color:"#444444"}}>Our vision is to democratize financial information and bring Wall Street like information to the masses.</p>
                            <p style={{color:"#444444"}}>We use artificial intelligence and generate smart analytics and intelligent content curation for the global financial markets. Our aim is to be exhaustive but precise.<br /> A tough challenge considering the expanse of information that is getting created.</p>
                            <p style={{color:"#444444"}}>Currently the engine covers US and Indian stocks, Currencies, Commodities and Indices. You can make your own watchlist of those companies whom you want<br /> to track.</p>
                            <p style={{color:"#444444"}}>Further, &nbsp;we use the power of social media, email, youtube to reach our audience where they hang out, thus breaking away from the traditional distribution <br /> channels of investment research and connecting with the millennial audience.</p>
                        </div>
                    </div>
                    {/* Features new :: Starts */}
                    <div className="container-fluid features-new" style={{marginLeft:"-15px"}}>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="features-new-box-header" style={{marginLeft:"-25px"}}>
                            <h1 className="fnb-title">Learn More About Our Features</h1>
                            <h4 className="fnb-sub-title">
                            We provide relevant market information completely personalized for you
                            </h4>
                            <span className="fnb-separator" />
                        </div>
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "-50px"}}>
                        <div className="col-md-6 col-lg-3">
                        <div className="features-new-look-wraper">
                            <div className="features-new-look-wraper-container">
                            <div className="features-new-look-wraper-content f-img" />
                            <div className="features-new-look-head">
                                Contextual
                                <br />
                                Search
                            </div>
                            </div>
                            <div className="features-new-look-wraper-hover">
                            <div className="features-new-look-wraper-hover-text">
                                <div className="features-new-look-wraper-hover-text-inn">
                                Contextual
                                <br />
                                Search
                                </div>
                            </div>
                            <div className="features-new-look-wraper-hover-lower-text">
                                Agrud curates content that are highly relevant &amp; focussed to you
                                or to your audience. Discover content instantly on the assets of
                                your choice. Stay informed!
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className="features-new-look-wraper">
                            <div className="features-new-look-wraper-container">
                            <div className="features-new-look-wraper-content f-img02" />
                            <div className="features-new-look-head">
                                Deep and Broad
                                <br /> Analytics
                            </div>
                            </div>
                            <div className="features-new-look-wraper-hover">
                            <div className="features-new-look-wraper-hover-text">
                                <div className="features-new-look-wraper-hover-text-inn">
                                Deep and Broad Analytics
                                </div>
                            </div>
                            <div className="features-new-look-wraper-hover-lower-text">
                                One stop shop for any quantitative analytics - in easy searchable
                                format. Converting data into information. Revolutionizing financial
                                journalism. Timely notifications.
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className="features-new-look-wraper">
                            <div className="features-new-look-wraper-container">
                            <div className="features-new-look-wraper-content f-img03" />
                            <div className="features-new-look-head">
                                Personalized
                                <br />
                                News Feed
                            </div>
                            </div>
                            <div className="features-new-look-wraper-hover">
                            <div className="features-new-look-wraper-hover-text">
                                <div className="features-new-look-wraper-hover-text-inn">
                                Personalized
                                <br /> News Feed
                                </div>
                            </div>
                            <div className="features-new-look-wraper-hover-lower-text">
                                You select the assets and we deliver the news. Our technology
                                creates a different financial news feed for every person. A
                                financial news feed just like your personalized notification on
                                social media sites.
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                        <div className="features-new-look-wraper">
                            <div className="features-new-look-wraper-container">
                            <div className="features-new-look-wraper-content f-img04" />
                            <div className="features-new-look-head">
                                Distribution and
                                <br /> Collaboration
                            </div>
                            </div>
                            <div className="features-new-look-wraper-hover">
                            <div className="features-new-look-wraper-hover-text">
                                <div className="features-new-look-wraper-hover-text-inn">
                                Distribution and
                                <br /> Collaboration
                                </div>
                            </div>
                            <div className="features-new-look-wraper-hover-lower-text">
                                Automatically deliver your content wherever you want. Web newspaper,
                                social networks, email newsletter to build your brand. Effortlessly
                                share anything.
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* /Features new :: Ends */}
                </div>
            </div>
        );
    }
}

export default About_agrud;
