import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment'
class Screeners extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            pathname:'',
            active_tab:"",
            security_details:[],
            security_values:[],
            all_values:[],
            new_date:null,
            market_close_date:null,
            base_url:'http://35.184.152.222:9999/'
        }
    }
    
    path_val(value){
        this.setState({pathname:value})
    }
    componentDidMount()
    {
      var url_value = window.location.pathname.replace("/","");
      this.setState({active_tab:url_value})

      axios.get(this.state.base_url+"get_portfolio_data/?user=14&portfolio=119&indicators=377,373,375,371,327,326,767,334,773,347,787,786,784,782,780,7,8,9,584,330,321,335,746,379,336", { 
          })
      .then(res => { 
        this.setState({security_details:res.data.security_details})
        this.setState({security_values:res.data.security_values})
        this.run_selected_value(res.data.security_details)
      })
    }
    

    run_selected_value = (security_details) =>{ 
      if(this.state.security_values[0] !== undefined)
      {
        var date_value = moment(this.state.security_values[0].ts_date).format('YYYY/MM/DD')
        this.setState({market_close_date:date_value})
        this.setState({close_date:this.state.security_values[0].ts_date})
        var new_date_value = moment(this.state.security_values[0].ts_date).format('MMM DD, YYYY')
        this.setState({new_date:new_date_value})
      }
     
      for(let i = 0; i < this.state.security_values.length; i++) {
        if(this.state.security_values[i]['master_id'] in security_details){
          security_details[this.state.security_values[i]['master_id']]['value_'+this.state.security_values[i]['indicator_id']]=this.state.security_values[i]['value']
        }
        if(i===this.state.security_values.length-1){
          /* eslint-disable */
          const obj = Object.keys(security_details)
          var dt=[]
          obj.forEach(key => {
            dt.push(security_details[key])
          });
          this.setState({all_values:dt})
        }
      }

    }

    get_chart = (exchange,symbol)=>{
      var url = "https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%2Fchart%2Fimages%2F"+exchange.toLowerCase()+"_"+symbol.toLowerCase()+"_ohlc.png"
      return url
    }

    render() {
      return (
        <div>
            {
                window.location.pathname === "/small-cap" || 
                window.location.pathname === "/large-cap" ||
                window.location.pathname === "/NASDAQ-most-active" ||
                window.location.pathname === "/automobiles-worst-performers" ||
                window.location.pathname === "/NASDAQ-52-week-high" ||
                window.location.pathname === "/small-cap-most-active-stocks" ||
                window.location.pathname === "/FAANG-stocks" ||
                window.location.pathname === "/large-cap-best-daily-perfomance" ||
                window.location.pathname === "/SP500-cheap-stocks" ||
                window.location.pathname === "/mid-cap-best-weekly-perfomance" ||
                window.location.pathname === "/SP500-highest-dividend-yield" ||
                window.location.pathname === "/SP500-best-yearly-perfomers" ||
                window.location.pathname === "/SP500-best-daily-performers" ||
                window.location.pathname === "/SP500-highest-dividend-yield" ||
                window.location.pathname === "/SP500-best-yearly-perfomers" ||
                window.location.pathname === "/tobacco-worst-perfomers" ||
                window.location.pathname === "/SP500-52-week-high" ||
                window.location.pathname === "/small-cap-cheap-stocks" ||
                window.location.pathname === "/dow-30-stocks" ||
                window.location.pathname === "/large-cap-best-monthly-perfomance" ||
                window.location.pathname === "/NASDAQ-highest-dividend-yield" ||
                window.location.pathname === "/warren-buffett-portfolio" 
                ?
                <div className="col-lg-12 pr-0" style={{marginLeft:"-17px",maxWidth:"102%",width:"102%"}}>
                    <section className="analytics-details news-block-container" id="analytics-details">
                    <div className="analytics-header">
                        <h1>{this.state.pathname.substr(1)}</h1>
                        <h1> as of Market Close: Jul 08th, 2020</h1>
                    </div>
                    {
                        window.location.pathname !== this.state.pathname ?
                        this.path_val(window.location.pathname)
                        :null
                    }
                    <div className="row" style={{marginTop:"-4px"}}>
                    <div className="col-sm-6">
                      <div className="row pb-3">
                        <div className="created-by analytics-share big-cat ml-3 mt-2">
                          <span
                          style={{width:"26.89px"}}
                            className="news-share-facebook action-btns-utils analytics-fb"
                          >
                            <i className="fa fa-facebook" aria-hidden="true" style={{width:"12px"}} />
                          </span>
                          <span
                            className="news-share-twitter action-btns-utils analytics-twitter"
                          >
                            <i className="fa fa-twitter" aria-hidden="true" style={{width:"14px"}} />
                          </span>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="col-sm-6" style={{fontSize:"14px",paddingRight:"15px",marginTop:"2px"}}>
                      <a
                        className="pull-right pt-2"
                        href="https://www.marketanalyst.ai/analytics/us-equities"
                      >
                        [ Click to View More Analytics ]
                      </a>
                    </div>
                  </div>
                    <table className="block-table-analytics table" width="100%" cellSpacing={0} cellPadding={0} border={0} style={{marginTop:"-4px"}}>
                      <thead className="analytics-block-head-text">
                        <tr>
                          <th>Symbol</th>
                          <th>Criteria <br />(  Price % Change )</th>
                          <th>Last Price</th>
                          <th className="text-center">Change</th>
                          <th className="text-center">WTD <br />(%)</th>
                          <th className="text-center">MTD <br />(%)</th>
                          <th className="text-center">QTD <br />(%)</th>
                          <th className="text-center">YTD <br />(%)</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className="analytics-block">
                      {
                        this.state.all_values !== null  ?
                        this.state.all_values.map((item,index)=>(
                        <React.Fragment key={index}>
                        <tr className="view equity-name assets-table" id="nyse-mac-analytics" data-type="F" data-uri={item.exchange_code+"-"+item.symbol} >
                          <td style={{paddingLeft: '6px', paddingTop: '6px', paddingBottom: '6px'}}>
                            <span className="screener-big-text" style={{paddingLeft: '2px'}}>
                              <a id="nyse-mac-ticker-big-text screener-big-text float-left" data-type="F" data-href={"https://www.marketanalyst.ai/markets/"+item.exchange_code+"-"+item.symbol+"/snapshots"} >{item.symbol}</a>
                            </span>
                            <span title="The Macerich Company" id="nyse-mac-ticker-small-text" className="ticker-small-text screener-small-text">{item.name.substring(0,10)}</span>
                          </td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px',color:"#444444"}} id="nyse-mac-ticker-DIVYIELD" className="nyse-mac-ticker-DIVYIELD parameter  text-align-left">
                            {((Math.round(item.value_326 * 100) / 100).toFixed(2))}
                          </td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px'}} id="nyse-mac-ticker-last-price-analytics" className="nyse-mac-last-price-r ticker-last-price-analytics text-align-left"><span style={{padding: '1px 3px',color:"#444444"}} >{item.value_371}</span></td>
                          <td className="text-time text-align-right" style={{position: 'relative', paddingTop: '6px', paddingBottom: '6px', paddingRight: '6px', padding: 0}}>
                            <span id="nyse-mac-change-text" className="nyse-mac-change ticker-last-price-change font_green">{
                              item.value_327 > 0 ? "+"+(Math.round(item.value_327 * 100) / 100).toFixed(2)  +'(+'+((Math.round(item.value_326 * 100) / 100).toFixed(2))+ '%)'
                                :(Math.round(item.value_327 * 100) / 100).toFixed(2)  +'('+((Math.round(item.value_326 * 100) / 100).toFixed(2))+ '%)'
                              }
                            </span>
                          <span id="nyse-mac-datetime" className="nyse-mac-close ticker-time-text margin-right-more" style={{right: '-28px', marginRight: '26px'}}>
                            Market Close {this.state.market_close_date.substring(5)}			</span>
                          </td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px'}} id="nyse-mac-ticker-wtd-analytics" className="ticker-wtd-analytics" style={{color: item.value_767 === 'NA' ? "#444" : item.value_767 > 0 ? "#444444" : "#ff333a",textAlign:"center" }}> {
                              item.value_767 !== undefined && item.value_767 !== ' ' && item.value_767 !== null?
                              (Math.round(item.value_767 * 100) / 100).toFixed(2)  
                          :'0.00'} </td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px'}} id="nyse-mac-ticker-mtd-analytics" className="ticker-mtd-analytics" style={{color: item.value_334 === 'NA' ? "#444" : item.value_334 > 0 ? "#444444" : "#ff333a",textAlign:"center" }}>
                          {
                              item.value_334 !== undefined && item.value_334 !== ' ' && item.value_334 !== null?
                              (Math.round(item.value_334 * 100) / 100).toFixed(2)  
                          :'0.00'} </td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px'}} id="nyse-mac-ticker-qtd-analytics" className="ticker-qtd-analytics" style={{color: item.value_773 === 'NA' ? "#444" : item.value_773 > 0 ? "#444444" : "#ff333a",textAlign:"center" }}>
                          {
                              item.value_773 !== undefined && item.value_773 !== ' ' && item.value_773 !== null?
                              (Math.round(item.value_773 * 100) / 100).toFixed(2)  
                          :'0.00'}</td>
                          <td style={{paddingTop: '6px', paddingBottom: '6px'}} id="nyse-mac-ticker-ytd-analytics" className="ticker-ytd-analytics" style={{color: item.value_347 === 'NA' ? "#444" : item.value_347 > 0 ? "#444444" : "#ff333a",textAlign:"center" }}>
                          {
                              item.value_347 !== undefined && item.value_347 !== ' ' && item.value_347 !== null?
                              (Math.round(item.value_347 * 100) / 100).toFixed(2)  
                          :'0.00'}</td>
                          <td style={{paddingRight: '5px', paddingLeft: '5px', paddingTop: '6px', paddingBottom: '6px', borderRight: 'solid 1px #e1e0e0'}}>
                            <div className="fancy-container border-right-0">
                              <span className="fa fa-angle-down">
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr className="fold" id="nyse-mac">
                          <td colSpan={9} className="p-0 pt-2">
                            <div className="fold-content" id="nyse-mac-fold">
                              <div className="col-xs-12 news-block pad-bot-20 pt-0">
                                <div className="news-content">
                                  <div title="Click to open NYSE:MAC- Snapshots page" className="header-banner" data-href="https://www.marketanalyst.ai/markets/nyse-mac/snapshots" data-target="_self">
                                    <div className="container_security_header">
                                      <div className="security_header_content">
                                        <div className="border-green nyse-mac-panel-border">
                                        </div>
                                        <div className="container">
                                          <div className="row">
                                            <div className="col-md-3 col-xl-3 newscontent_left_panel pl-0">
                                              <div className="col-sm-12 w-150 float_left screener_header_name equity-name ml-2" data-uri="nyse-mac" title="MACERICH CO">{item.name.substring(0,10)}</div>
                                              <div className="col-sm-12 w-150 float_left screener_header_ticker ml-2 ">{item.exchange_code}-{item.symbol}</div>
                                              <div className="col-sm-12 w-150 float_left market_close_time ml-2 float-left nyse-mac-close ">Market Close {this.state.market_close_date.substring(5)}</div>
                                            </div>
                                            <div className="col-md-4 col-xl-4 newscontent_middle_panel pl-4 ">
                                              <span className="header_price nyse-mac-last-price">{item.value_371}</span>
                                              <span className="fund-tablink-price-currency">USD </span>
                                              <span className="d-block green-percent-text-fill nyse-mac-panel " style={{color: item.value_327 > 0 ? "#52df09" : "#ff333a",fontSize:"17px",fontFamily:"arial" }}>
                                                {(Math.round(item.value_327 * 100) / 100).toFixed(2)  +'('+((Math.round(item.value_326 * 100) / 100).toFixed(2))+ '%)'} 
                                                {
                                                  item.value_327 < 0 || item.value_327 === undefined || item.value_327 === null ?
                                                  <sup className="#ff333a" style={{marginLeft:"7px",top:"0.0em",fontSize: "21px"}}>
                                                  <i className="fa fa-sort-down " />
                                                  </sup>
                                                  :
                                                  <sup className="#2e914f" style={{marginLeft:"7px",top:"0.2em",fontSize: "21px"}}>
                                                  <i className="fa fa-sort-up" />
                                                  </sup>
                                                  }</span>                      
                                                  </div>
                                            <div className="col-md-5 col-xl-5 newscontent_right_panel">
                                              <div className="row container_openprice">
                                                <div className="col-md-5 col-xl-4 container_openprice_left_txt">OPEN:</div>
                                                <div className="col-md-7 col-xl-8 container_openprice_right_price_value nyse-mac-open">{
                                                      item.value_377 !== undefined && item.value_377 !== ' ' && item.value_377 !== null?
                                                      item.value_377
                                                      :'0.00'
                                                }</div>
                                              </div>
                                              <div className="row container_openprice">
                                                <div className="col-md-5 col-xl-5 container_openprice_left_txt">DAY RANGE:</div>
                                                <div className="col-md-7 col-xl-7 container_openprice_right_price_value nyse-mac-range">
                                                  {
                                                      item.value_375 !== undefined && item.value_375 !== ' ' && item.value_375 !== null?
                                                      (Math.round(item.value_375 * 100) / 100).toFixed(2)  
                                                  :null
                                                  } - {
                                                      item.value_373 !== undefined && item.value_373 !== ' ' && item.value_373 !== null?
                                                      ((Math.round(item.value_373 * 100) / 100).toFixed(2))
                                                      :null
                                                  }
                                                </div>
                                              </div>
                                              <div className="row different_price_range market-close ">
                                                <div className="col-md-2 pe_pb_ps_text">PE:</div>
                                                <div className={item.value_784 > 0 ? "col-md-2 pe_pb_ps_value font_green" : "col-md-2 pe_pb_ps_value font_red"}>
                                                {
                                                    item.value_784 !== undefined && item.value_784 !== ' ' && item.value_784 !== null?
                                                    (Math.round(item.value_784 * 100) / 100).toFixed(2)  
                                                    :'0.00'
                                                }
                                                </div>
                                                <div className="col-md-2 pe_pb_ps_text">PB:</div>
                                                <div className={item.value_782 > 0 ? "col-md-2 pe_pb_ps_value font_green" : "col-md-2 pe_pb_ps_value font_red"}>
                                                {
                                                    item.value_782 !== undefined && item.value_782 !== ' ' && item.value_782 !== null?
                                                    (Math.round(item.value_782 * 100) / 100).toFixed(2)  
                                                    :'0.00'
                                                }
                                                </div>
                                                <div className="col-md-2 pe_pb_ps_text">PS:</div>
                                                <div className={item.value_780 > 0 ? "col-md-2 pe_pb_ps_value font_green" : "col-md-2 pe_pb_ps_value font_red"}>
                                                {
                                                    item.value_780 !== undefined && item.value_780 !== ' ' && item.value_780 !== null?
                                                    (Math.round(item.value_780 * 100) / 100).toFixed(2)  
                                                    :'0.00'
                                                }
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row container_wtd_mtd_qtd_ytd market-close ">
                                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">WTD % <span className={item.value_767 > 0 ? "font_green" : "font_red"  }>
                                            {
                                                  item.value_767 !== undefined && item.value_767 !== ' ' && item.value_767 !== null?
                                                  (Math.round(item.value_767 * 100) / 100).toFixed(2)  
                                              :'0.00'} 
                                            </span>
                                            </div>
                                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">MTD % <span className={item.value_334 > 0 ? "font_green" : "font_red"}>
                                            {
                                              item.value_334 !== undefined && item.value_334 !== ' ' && item.value_334 !== null?
                                              (Math.round(item.value_334 * 100) / 100).toFixed(2)  
                                              :'0.00'
                                              } 
                                            </span>
                                            </div>
                                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">QTD % <span className={item.value_773 > 0 ? "font_green" : "font_red"}>
                                            {
                                                  item.value_773 !== undefined && item.value_773 !== ' ' && item.value_773 !== null?
                                                  (Math.round(item.value_773 * 100) / 100).toFixed(2)  
                                                  :'0.00'
                                              }
                                            </span>
                                            </div>
                                            <div className="col-md-3 col-xl-3 wtd_mtd_qtd_ytd_text">YTD % <span className={item.value_347 > 0 ? "font_green" : "font_red"}>
                                            {
                                                  item.value_347 !== undefined && item.value_347 !== ' ' && item.value_347 !== null?
                                                  (Math.round(item.value_347 * 100) / 100).toFixed(2)  
                                              :'0.00'}
                                            </span>
                                            </div>
                                          </div>
                                          <div className="row market-close-highlight market-close ">
                                            <div className="float-left ml-4">* Updated at market close</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="container du-container cont-full">
                                <div className="row row-eq-height">
                                  <div className="col-12 marg-top-0 pr-0 pl-0">
                                    <div className="du-chart-container du-ohlc">
                                      <div className="consumer-chart">
                                        <div className="row px-3 pt-3 default">
                                          <div title="Click to open (NYSE:MAC)- Snapshots page" className="horizontal-middle pointer" style={{backgroundColor: '#fffaf7'}} data-title="3 Years Price Graph">
                                            {/*							<img alt="3 Years OHLC Graph (NYSE:MAC)" class="du-img du-chart-img-ohlc lazy analytics-image" data-src="http://35.184.152.222/chart/images/nyse_mac_ohlc.png" data-href="https://www.marketanalyst.ai/markets/nyse-mac/snapshots" src="http://35.184.152.222/chart/images/nyse_mac_ohlc.png" >*/}
                                            <img alt="3 Years OHLC Graph (NYSE:MAC)" className="du-img du-chart-img-ohlc lazy analytics-image" data-src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%2Fchart%2Fimages%2Fnyse_mac_ohlc.png" data-href={"https://www.marketanalyst.ai/markets/"+item.exchange_code.toLowerCase()+"-"+item.symbol.toLowerCase()+"/snapshots"} src={this.get_chart(item.exchange_code,item.symbol)} />
                                          </div>
                                        </div>
                                        <div className="news-summary-css go-to-snapshots" title="Click to open nyse-mac - Snapshots page" data-href="https://www.marketanalyst.ai/markets/nyse-mac/snapshots" style={{fontSize:"12px",color:"#000000"}}>
                                          <span className="news-summary">{item.name} ({item.exchange_code}:{item.symbol}) opened on {this.state.new_date} at {item.value_371} USD and went marginally {
                                                    item.value_326 > 5 ?
                                                    ' climbed '
                                                      :
                                                      item.value_326 > 2 ?
                                                      ' gained nearly '
                                                      :  item.value_326 > 0 ? 
                                                          item.value_326 < 1 ?
                                                            ' up '
                                                            :
                                                            ' increased '
                                                        :
                                                        item.value_326 < -1 ?
                                                          ' decrease '
                                                          :
                                                          ' down '

                                                  } {
                                                    item.value_326 < 2 && item.value_326 > 5 ?
                                                      ' by '
                                                      : null
                                                  }  {(Math.round(item.value_326 * 100) / 100).toFixed(2)+ '%'}. The intraday price range was  {
                                                    item.value_375 !== undefined && item.value_375 !== ' ' && item.value_375 !== null?
                                                    (Math.round(item.value_375 * 100) / 100).toFixed(2)  
                                                  :null
                                                  } - {
                                                    item.value_373 !== undefined && item.value_373 !== ' ' && item.value_373 !== null?
                                                    ((Math.round(item.value_373 * 100) / 100).toFixed(2))
                                                    :null} USD and eventually ended the session at {item.value_371 !== undefined && item.value_371 !== ' ' && item.value_371 !== null?
                                                    (Math.round(item.value_371 * 100) / 100).toFixed(2)
                                                    :'0.00'
                                                    } USD. The stock trade volume was   
{
                                                  Math.abs(Number(item.value_379)) >= 1.0e9
                                                      ? ' '+(Math.sign(Number(item.value_379)) * (Math.abs(Number(item.value_379)) / 1.0e9).toFixed(2)) + " B "
                                                      :
                                                      Math.abs(Number(item.value_379)) >= 1.0e6
                                                      ? ' '+(Math.sign(Number(item.value_379)) * (Math.abs(Number(item.value_379)) / 1.0e6).toFixed(2)) + " M "
                                                      : 
                                                      Math.abs(Number(item.value_379)) >= 1.0e3
                                                      ? ' '+(Math.sign(Number(item.value_379)) * (Math.abs(Number(item.value_379)) / 1.0e3).toFixed(2)) + " K "
                                                      : ' '+(Math.abs(Number(item.value_379)).toFixed(2))}

                                                    shares. The company's 52-week range is {item.value_321 !== undefined && item.value_321 !== ' ' && item.value_321 !== null?
                                                    (Math.round(item.value_321 * 100) / 100).toFixed(2)
                                                    :'0.00'
                                                    } USD.</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        </React.Fragment>
                        ))
                        :null
                      }
                      </tbody>
                    </table>
                    </section>
                </div>:null

            }
           
        </div>
      );
    }
}

export default Screeners;
