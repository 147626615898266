import React, { Component } from 'react';
import Aboutagrud from './About_agrud'
import Advisors from './Adviser'
import Careers from './Careers'
import FAQ from './Faq'
class About extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            active_tab:'',
            hash_value:''
        }
    }
    
    componentDidMount()
    {
        var url = window.location.hash
        this.setState({hash_value:url})
        if (url === '#teams-advisors') {
            this.change_tab('advisors')
            this.setState({active_tab:'advisors'})
        }

        if (url === '#life') {
            this.change_tab('careers')
            this.setState({active_tab:'careers'})
        }

        if (url === '#faq') {
            this.change_tab('faq')
            this.setState({active_tab:'faq'})
        }

        
    }

    change_tab = (value) =>{
        this.setState({active_tab:value})
        this.setState({hash_value:'#'+value})
    }
    render() {
        console.log('active_tab',this.state.active_tab)
        const menu={
            textAlign: "center",
            padding: "1rem!important",
            borderLeft: "1px solid #dedede",
            borderBottom: "1px solid #dedede"
        }
        return (
        <div className="container-fluid ">
            <div className="row" style={{height:"53px"}}>
            <div className={this.state.active_tab === 'about_agrud' ? "col-md-3  " : "col-md-3 "} style={{paddingTop:"16px",marginRight:"7px",textAlign: "center",fontSize:"14px",color:"#000000",marginLeft:"0px",marginTop:"-3px",padding: "1rem!important",borderLeft: "1px solid #dedede",borderBottom: "1px solid #dedede",backgroundColor: this.state.active_tab !== 'about_agrud' ? "#eeeeee":  "white" }}  onClick={()=>this.change_tab('about_agrud')}>
            <p className="menu-item" style={{marginLeft:"-8px"}}>ABOUT AGRUD</p>
            </div>
            <div className={this.state.active_tab === 'advisors' ? "col-md-3  " : "col-md-3 "} style={{paddingTop:"16px",marginRight:"6px",textAlign: "center",padding: "1rem!important",fontSize:"14px",color:"#000000",marginLeft:"-14px",marginTop:"-3px",borderLeft: "1px solid #dedede",borderBottom: "1px solid #dedede",backgroundColor: this.state.active_tab !== 'advisors' ? "#eeeeee":"white" }} onClick={()=>this.change_tab('advisors')}>
            <p className="menu-item" style={{marginLeft:"-9px"}}>TEAM & ADVISORS</p>
            </div>
            <div className={this.state.active_tab === 'careers' ? "col-md-3  " : "col-md-3 "} style={{paddingTop:"16px",marginRight:"6px",textAlign: "center",padding: "1rem!important",fontSize:"14px",color:"#000000",marginLeft:"-14px",marginTop:"-3px",borderLeft: "1px solid #dedede",borderBottom: "1px solid #dedede",backgroundColor: this.state.active_tab !== 'careers' ? "#eeeeee":"white" }} onClick={()=>this.change_tab('careers')}>
            <p className="menu-item" style={{marginLeft:"-8px"}}>LIFE & CAREERS</p>
            </div>
            <div className={this.state.active_tab === 'faq' ? "col-md-3  " : "col-md-3 "} style={{paddingTop:"16px",textAlign: "center",padding: "1rem!important",fontSize:"14px",color:"#000000",marginLeft:"-14px",marginTop:"-3px",borderLeft: "1px solid #dedede",borderBottom: "1px solid #dedede",backgroundColor: this.state.active_tab !== 'faq' ? "#eeeeee":"white" }} onClick={()=>this.change_tab('faq')}>
            <p className="menu-item" style={{marginLeft:"-8px"}}>FAQ</p>
            </div>
            </div>
            {
                this.state.active_tab === 'about_agrud' || this.state.hash_value === ''  ?
                <Aboutagrud />
                :
                this.state.active_tab === 'advisors'    ?
                <Advisors />
                :
                this.state.active_tab === 'careers' ?
                <Careers />
                :
                this.state.active_tab === 'faq' ?
                <FAQ />
                :null
            }
        </div>
        );
    }
}

export default About;
