import React, { Component } from 'react';
class News extends Component {
  constructor(props)
  {
    super()
  }

  render() {
    return (
      <div id="news" className="container tab-pane active">
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.investing.com/news/economic-indicators/treasuries-on-steroids-us-banks-mortgage-bond-trading-bonanza-2320146" className="news-heading" data-url="https://www.investing.com/news/economic-indicators/treasuries-on-steroids-us-banks-mortgage-bond-trading-bonanza-2320146">
                  <span className="news-headings">'Treasuries on steroids': U.S. banks' mortgage bond trading bonanza</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>investing</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  The Fed's coronavirus stimulus measures are responsible for the rally in mortgage bonds guaranteed by government agencies Ginnie Mae, Fannie Mae and Freddie Mac (OTC: FMCC ), say investors.
                  The premia that investors demanded to hold these bonds over risk-free Treasury debt, as measured by the Ginnie, Fannie and Freddie MBS ICE (NYSE: ICE ) BofA indexes, were the widest since the 2007-2009 financial crisis in mid-March.
                  Average daily trading volume in agency RMBS hit a peak in March of this                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.investing.com/news/economic-indicators/treasuries-on-steroids-us-banks-mortgage-bond-trading-bonanza-2320146" data-url="https://www.marketanalyst.ai/summary/202010090709230006">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.investing.com/news/commodities-news/why-gazproms-76-billion-nord-stream-2-fine-is-just-the-start-2320128" className="news-heading" data-url="https://www.investing.com/news/commodities-news/why-gazproms-76-billion-nord-stream-2-fine-is-just-the-start-2320128">
                  <span className="news-headings">Why Gazprom’s $7.6 Billion Nord Stream 2 Fine Is Just the Start</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>investing</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  (Bloomberg) -- Poland’s antitrust watchdog slapped a $7.6 billion fine on Gazprom PJSC (OTC: OGZPY ) over the Nord Stream 2 pipeline, opening a new front in                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.investing.com/news/commodities-news/why-gazproms-76-billion-nord-stream-2-fine-is-just-the-start-2320128" data-url="https://www.marketanalyst.ai/summary/202010090709150007">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.morningstar.com/news/dow-jones/202010088336/eaton-vance-shares-up-485-after-7-billion-morgan-stanley-deal" className="news-heading" data-url="https://www.morningstar.com/news/dow-jones/202010088336/eaton-vance-shares-up-485-after-7-billion-morgan-stanley-deal">
                  <span className="news-headings">Eaton Vance Shares Up 48.5% After $7 Billion Morgan Stanley Deal</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>morningstar</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  Eaton Vance Corp. shares were up 48.5% to $60.79 in early afternoon trading.
                  Morgan Stanley said earlier it would acquire Eaton Vance for $7 billion.
                  Under the deal, Eaton Vance shareholders would get a total consideration of $56.50 per share in cash and stock.
                  Shareholders also would receive a one-time special cash dividend of $4.25 per share pre-closing by Eaton Vance from balance-sheet                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.morningstar.com/news/dow-jones/202010088336/eaton-vance-shares-up-485-after-7-billion-morgan-stanley-deal" data-url="https://www.marketanalyst.ai/summary/202010090708230005">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.fox23.com/news/stocks-climb-again/NDFTDKNPNYAF6HAOHGWPQ5OUPY/" className="news-heading" data-url="https://www.fox23.com/news/stocks-climb-again/NDFTDKNPNYAF6HAOHGWPQ5OUPY/">
                  <span className="news-headings">Asian stocks follow Wall Street higher on stimulus hopes</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>fox23</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  BEIJING — (AP) — Asian stock markets followed Wall Street higher on Friday on hopes Washington will provide more aid to the struggling U.S. economy.
                  Trump said in a TV interview that “very productive” talks had begun on more stimulus after supplemental unemployment benefits that supported consumer spending, the engine of the U.S. economy, expired.
                  Wall Street's benchmark S&amp;P 500 index rose 0.8% to 3,446.83.
                  In energy markets, benchmark U.S. crude lost 9 cents to $41.10 per barrel in                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.fox23.com/news/stocks-climb-again/NDFTDKNPNYAF6HAOHGWPQ5OUPY/" data-url="https://www.marketanalyst.ai/summary/202010090708210002">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.marketbeat.com/instant-alerts/nyse-ksu-earnings-date-2020-10/" className="news-heading" data-url="https://www.marketbeat.com/instant-alerts/nyse-ksu-earnings-date-2020-10/">
                  <span className="news-headings">Kansas City Southern (KSU) Scheduled to Post Quarterly Earnings on Friday</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>marketbeat</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  Wells Fargo &amp; Company raised their target price on Kansas City Southern from $176.00 to $180.00 and gave the stock an "overweight" rating in a report on Monday, July 20th.
                  Deutsche Bank reduced their target price on Kansas City Southern from $166.00 to $163.00 and set a "hold" rating on the stock in a report on Wednesday, June 17th.
                  JPMorgan Chase &amp; Co. upgraded Kansas City Southern from an "underweight" rating to a "neutral" rating and raised their target price for the stock from $142.00 to                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.marketbeat.com/instant-alerts/nyse-ksu-earnings-date-2020-10/" data-url="https://www.marketanalyst.ai/summary/202010090708010016">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.marketbeat.com/instant-alerts/nasdaq-payx-consensus-analyst-rating-2020-10/" className="news-heading" data-url="https://www.marketbeat.com/instant-alerts/nasdaq-payx-consensus-analyst-rating-2020-10/">
                  <span className="news-headings">Zacks: Brokerages Expect Paychex, Inc. (NASDAQ:PAYX) Will Post Quarterly Sales of $949.56 Million</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>marketbeat</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  JPMorgan Chase &amp; Co. upped their price objective on shares of Paychex from $72.00 to $78.00 and gave the stock an "underweight" rating in a research report on Monday, August 17th.
                  Valeo Financial Advisors LLC now owns 1,248 shares of the business services provider's stock valued at $95,000 after acquiring an additional 142 shares during the last quarter.
                  Parsons Capital Management Inc. RI now owns 29,483 shares of the business services provider's stock valued at $2,233,000 after acquiring an                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.marketbeat.com/instant-alerts/nasdaq-payx-consensus-analyst-rating-2020-10/" data-url="https://www.marketanalyst.ai/summary/202010090708010015">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  5 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.cioreview.com/news/f5-introduces-aipowered-solution-that-blocks-fraud-missed-by-existing-technologies-and-improves-online-experiences-for-users-pnid-371-cid-50.html" className="news-heading" data-url="https://www.cioreview.com/news/f5-introduces-aipowered-solution-that-blocks-fraud-missed-by-existing-technologies-and-improves-online-experiences-for-users-pnid-371-cid-50.html">
                  <span className="news-headings">F5 Introduces AI-powered Solution that Blocks Fraud Missed by Existing Technologies and Improves Online Experiences for Users</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>cioreview</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  Legacy fraud tools rely on weak data signals and static rules to try to catch increasingly clever fraudsters.
                  As a fully managed service that can detect and block evolving threats, SAFE protects modern and traditional applications, helping organisations accelerate digital efforts and lessen the often-overwhelming workload on fraud teams.
                  One large North American bank detected 250% more account takeover fraud in 60 days with SAFE than with their existing tools, resulting in the elimination of an                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.cioreview.com/news/f5-introduces-aipowered-solution-that-blocks-fraud-missed-by-existing-technologies-and-improves-online-experiences-for-users-pnid-371-cid-50.html" data-url="https://www.marketanalyst.ai/summary/202010090708010013">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.marketbeat.com/instant-alerts/nyse-nue-consensus-analyst-rating-2020-10/" className="news-heading" data-url="https://www.marketbeat.com/instant-alerts/nyse-nue-consensus-analyst-rating-2020-10/">
                  <span className="news-headings">Nucor Co. (NYSE:NUE) Expected to Announce Quarterly Sales of $4.45 Billion</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>marketbeat</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  BMO Capital Markets increased their price target on shares of Nucor from $46.00 to $50.00 and gave the company a "market perform" rating in a research report on Friday, September 18th.
                  Finally, Deutsche Bank upped their price objective on shares of Nucor from $42.00 to $46.00 and gave the company a "hold" rating in a research note on Thursday, October 1st.
                  In other Nucor news, CEO Leon J. Topalian sold 9,389 shares of the stock in a transaction that occurred on Tuesday, July 28th.
                  Cypress                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.marketbeat.com/instant-alerts/nyse-nue-consensus-analyst-rating-2020-10/" data-url="https://www.marketanalyst.ai/summary/202010090708010011">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  4 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.marketbeat.com/instant-alerts/nyse-hri-a-buy-or-sell-right-now-2020-10/" className="news-heading" data-url="https://www.marketbeat.com/instant-alerts/nyse-hri-a-buy-or-sell-right-now-2020-10/">
                  <span className="news-headings">Herc (NYSE:HRI) Raised to "Strong-Buy" at Zacks Investment Research</span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>marketbeat</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  Several other equities analysts have also commented on HRI. ValuEngine raised Herc from a "sell" rating to a "hold" rating in a research note on Tuesday, September 1st.
                  Finally, BofA Securities raised Herc from a "neutral" rating to a "buy" rating in a research note on Wednesday, July 8th.
                  A number of hedge funds and other institutional investors have recently modified their holdings of HRI. Advisor Group Holdings Inc. purchased a new stake in Herc during the 1st quarter worth about                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.marketbeat.com/instant-alerts/nyse-hri-a-buy-or-sell-right-now-2020-10/" data-url="https://www.marketanalyst.ai/summary/202010090708010006">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="row content-newsbox">
          <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
            <div className="news-header news-bottom-gape">
              <span className="news-source right-one-text">[  13 mins ago ]</span>
              <span className="news-title content-newsbox-heading">
                <a target="_blank" href="https://www.aeresearch.net/crisis-management-service-market-322806" className="news-heading" data-url="https://www.aeresearch.net/crisis-management-service-market-322806">
                  <span className="news-headings">Crisis Management Service  Market Report 2020, Trends, Competitive Landscape and Opportunities </span>
                </a>
              </span>
              <span className="sourced">Story sourced from: <strong>aeresearch</strong></span>
            </div>
            <div className="news-content">
              <div className="news-summary-wrapper">
                <span className="news-summary news-textsize-change shorten">
                  In the end, Crisis Management Service Market report delivers a conclusion which includes Research Findings, Market Size Evaluation, Global Market Share, Consumer Needs along with Customer Preference Change, Data Source.
                  Global COVID-19 Impact on Global Ridesharing Services Market Report industry size, share, growth, trends and forecast analysis up to 2026.
                  Global COVID-19 Impact on Global Mycoplasma Detection Service Market Report industry size, share, growth, trends and forecast analysis up to                            <a target="_blank" className="text-primary morelink" data-newsurl="https://www.aeresearch.net/crisis-management-service-market-322806" data-url="https://www.marketanalyst.ai/summary/202010090659010003">...<i>See Summary</i></a>
                </span></div>
            </div>
          </div>
        </div>
        <div className="news-load mb-3"> 
          <button className="agrud-button agrud-button-round load-more mt-3" data-page-num={1} data-ticker="US-EQUITIES">Load More News</button>
          <span id="old_news" className="loader" style={{display: 'none'}}><img src="https://www.marketanalyst.ai/assets/common/images/ajax-loader-white-horizontal.gif" /></span>
        </div>
      </div>
    );
  }
}

export default News;