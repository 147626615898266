import React, { Component } from 'react';
import Analytics from './Analytics'
import Major from './Major_indicate'
import News from './News'
// import axios from 'axios';
class Market extends Component {
  constructor(props)
  {
    super(props)
    this.state = {
      base_url:'http://35.184.152.222:9999'
    }
  }
  componentDidMount()
  {
    // var data = {
    //   master_id:'45406,45549',
    //   portfolio_id:2,
    //   indicator_id:'377,379',
    //   date_start:'2020-06-10,00:00:00',
    //   date_end:'2020-06-10,00:00:00',
    //   last:40,
    //   page:'2,30'
    // }

    // axios.post(this.state.base_url+"get_news",data, { 
    // })
    // .then(res => {
    //   console.log(res);
    // })
  }
  render() {
    const container ={
      marginTop:"20px"
    }
    const tab_view = {
      height: "36px",
      fontSize:"14px"
    }
    
    return (
      <div style={{marginTop: "-20px",marginLeft: "-18px",width:"104%"}}>
        <div className="container" style={container}>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a className="nav-item nav-link active" style={tab_view} id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">News</a>
                    <a className="nav-item nav-link" id="nav-profile-tab" style={tab_view} data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Analytics</a>
                    <a className="nav-item nav-link" id="nav-contact-tab" style={tab_view} data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Major Indices</a>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent" style={{width:"99%"}}>
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <News />
                </div>
                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <Analytics />
                </div>
                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" style={{width:"101%"}}>
                    <Major />
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Market;
