import React, { Component } from 'react';
// import Aboutagrud from './About_agrud'
// import Advisors from './Adviser'
// import Careers from './Careers'
// import FAQ from './Faq'
import Tab from './Tab_menu'
class About extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            active_tab:'about_agrud'
        }
    }

    change_tab = (value) =>{
        this.setState({active_tab:value})
    }
    render() {
        return (
        <div style={{width:"102%"}}>
            <div id="about_us" className="container-fluid  about_us_banner p-0">
                <div className="col-md-12 p-0">
                <img alt="" id="home-banner-image" style={{position: 'absolute', height: '315px', width: '98%',marginTop:"42px"}} src="https://www.marketanalyst.ai/assets/common/images/intro-bg-about.jpg" />
                <span className="banner-wrapper" style={{marginTop:"-4px"}}>
                <span className="word-stella">Stella,</span><br />
                <span className="line-one" style={{marginTop:"-8px"}}>a FinTech Engine for Investment Research</span><br />
                <span className="line-two" style={{marginTop:"-8px"}}>Democratizing Financial Services</span>
                </span>
            </div>
            </div><br />
            <br />
            <Tab />
        </div>
        );
    }
}

export default About;
