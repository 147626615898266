import React, { Component } from 'react';

class Analytics extends Component {
  render() {
    return (
      <div>
        <div id="analytics" className="container tab-pane active show">
        <div className="row">
          <div className="col-sm-12">
            <div className="accordion custom-accordion" id="analytics-accordian" role="tablist" aria-multiselectable="true" style={{textAlign: 'left',width:"102%"}}>
              {/* This will run in a loop Start*/}
              <div className="card" id="most-active-stocks-group">
                <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse0" aria-expanded="false" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                  <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx" href="#collapse0" aria-expanded="false" aria-controls="collapse0">
                    <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                      Most Active Stocks<i className="fa fa-angle-down rotate-icon" style={{marginTop: '3px'}} />
                    </h5>
                  </a>
                </div>
                <div id="collapse0" className="in collapse" aria-expanded="true" style={{}}>
                  <div className="card-body">
                    <section className="analytics_items_container_panel" id="most-active-stocks-panel">
                      <div id="most-active-stocks">
                        <div className="analytics-count"> 
                          <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                          Showing 30 of 103 results. 
                          Please <a href="https://www.marketanalyst.ai/analytics/us-equities/most-active-stocks" style={{fontWeight: 'bold'}}>click here</a> for the full list
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>N</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/nasdaq-most-active" className="news-heading">
                                  <span className="news-headings">NASDAQ - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active - NASDAQ 100</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-information-technology-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Information Technology - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-information-technology-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Information Technology - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-information-technology-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Information Technology - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Information Technology - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-consumer-discretionary-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Consumer Discretionary - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Discretionary - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-consumer-discretionary-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Consumer Discretionary - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Discretionary - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-consumer-discretionary-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Consumer Discretionary - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Discretionary - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active Large Cap stocks - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-health-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Health - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-health-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Health - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-health-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Health - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Health - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-energy-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Energy - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-energy-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Energy - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-energy-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Energy - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Energy - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-industrials-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Industrials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Industrials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-industrials-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Industrials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Industrials - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-industrials-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Industrials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Industrials - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-consumer-staples-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Consumer Staples - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Staples - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-consumer-staples-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Consumer Staples - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Staples - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-consumer-staples-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Consumer Staples - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Consumer Staples - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-materials-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Materials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Materials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-materials-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Materials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Materials - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-materials-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Materials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Materials - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-telecommunications-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Telecommunications - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Telecommunications - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-telecommunications-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Telecommunications - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Telecommunications - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-telecommunications-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Telecommunications - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Telecommunications - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-utilities-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Utilities - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Utilities - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="analytics-count"> 
                          <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                          Showing 30 of 103 results. 
                          Please <a href="https://www.marketanalyst.ai/analytics/us-equities/most-active-stocks" style={{fontWeight: 'bold'}}>click here</a> for the full list
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div className="card" id="analytics-on-financials-sector-group">
                <div className="card-header" data-toggle="collapse" data-parent="#accordionEx" href="#collapse1" aria-expanded="true" aria-controls="collapse0" role="tab" id="heading0" style={{backgroundColor: 'rgba(211, 211, 211, 0.4)'}}>
                  <a data-toggle="collapse" data-parent="#accordionEx" href="#collapse1" aria-expanded="true" aria-controls="collapse0">
                    <h5 className="card-header-text" style={{color: 'rgb(0, 163, 228)'}}>
                      Analytics on Financials Sector<i className="fa fa-angle-down rotate-icon" />
                    </h5>
                  </a>
                </div>
                <div id="collapse1" className="collapse in" aria-expanded="true" style={{}}>
                  <div className="card-body">
                    <section className="analytics_items_container_panel" id="analytics-on-financials-sector-panel">
                      <div id="analytics-on-financials-sector">
                        <div className="analytics-count"> 
                          <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                          Showing 30 of 51 results. 
                          Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-financials-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Mid Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Large Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-financials-most-active" className="news-heading">
                                  <span className="news-headings">Small Cap - Financials - Most Active</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Most Active stocks in Financials - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-best-performers" className="news-heading">
                                  <span className="news-headings">Mid Cap - Financials - Best Performers</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Yesterday's Best Performing stocks in Financials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-daily-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Best Daily Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Financials - Yesterday's Top performers - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-daily-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Best Daily Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Financials - Yesterday's Top performers - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-daily-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Worst Daily Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Mid Cap Financials- Yesterday's Worst performers - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-daily-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Worst Daily Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Large Cap Financials - Yesterday's Worst performers - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-daily-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Worst Daily Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Small Cap Financials - Yesterday's Worst performers - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-financials-highest-dividend-yield" className="news-heading">
                                  <span className="news-headings">Mid Cap - Financials - Highest Dividend Yield</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - SP400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-financials-highest-dividend-yield" className="news-heading">
                                  <span className="news-headings">Large Cap - Financials - Highest Dividend Yield</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - RUSSELL1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-financials-highest-dividend-yield" className="news-heading">
                                  <span className="news-headings">Small Cap - Financials - Highest Dividend Yield</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Highest Dividend Yielding stocks in Financials - RUSSELL2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Best Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Best Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Best Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Week to Date performers Financials - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Worst Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Worst Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-weekly-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Worst Weekly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Week to Date performers Financials - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Best Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#006a9d'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Best Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#545556'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Best Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Month to Date performers Financials - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#5e39b2'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Worst Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Worst Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-worst-monthly-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Worst Monthly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Month to Date performers Financials - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-best-quarterly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Best Quarterly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-best-quarterly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Best Quarterly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a32e40'}}>M</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/mid-cap-best-quarterly-performers-financials" className="news-heading">
                                  <span className="news-headings">Mid Cap - Best Quarterly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Top Quarter to Date performers Financials - S&amp;P 400</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#a37b35'}}>L</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/large-cap-worst-quarterly-performers-financials" className="news-heading">
                                  <span className="news-headings">Large Cap - Worst Quarterly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Financials - RUSSELL 1000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#725c2c'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-quarterly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Worst Quarterly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Quarter to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="content-analytics-box">
                          <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                            <div className="analytics-content-img-box" style={{backgroundColor: '#4f79a0'}}>S</div>
                          </div>
                          <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                            <div className="news-header">
                              <span className="title-link">
                                <span className="news-source left-one-text">[ agrud.com ]</span>
                              </span>
                              <span className="news-title content-newsbox-heading">
                                <a target="_self" href="https://www.marketanalyst.ai/analytics/us-equities/small-cap-worst-yearly-performers-financials" className="news-heading">
                                  <span className="news-headings">Small Cap - Worst Yearly Performers - Financials</span>
                                  <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                                </a>
                              </span>
                            </div>
                            <div className="news-content">
                              <div className="news-summary-wrapper">
                                <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Worst Year to Date performers Financials - RUSSELL 2000</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="analytics-count"> 
                          <i className="fa fa-2x fa-info-circle" aria-hidden="true" /> 
                          Showing 30 of 51 results. 
                          Please <a href="https://www.marketanalyst.ai/analytics/us-equities/analytics-on-financials-sector" style={{fontWeight: 'bold'}}>click here</a> for the full list
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
             {/* End */}</div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Analytics;
