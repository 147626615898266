import React, { Component } from 'react';

class Faq extends Component {
    render() {
        return (
        <div>
            <div className="container content-container faq">
            <div className="row">
            <div className="col-md-12">
                <h2>Frequently Asked Questions</h2>
                <div className="widget about-at pad-top">
                <div className="panel-group left-faq" id="faqa1">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa1" href="#collapsea1">
                            What is Agrud.com all about?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea1" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Agrud.com is an artificial intelligence enabled analytics and content curation engine for the global financial markets. Currently, it covers US Equities, Currencies, Commodities and Index markets. You can make your own watchlist of the companies which you want to track. Our engine will keep you updated with all the day to day developments in financial markets at the earliest. We are here to democratize financial information and bring Wall Street type information to the masses.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa2">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa2" href="#collapsea2">
                            How is Agrud different from other stock market news feed sites?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea2" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>We publish relevant financial information curated from the internet after summarizing it into quick bite size containing important point from the news. What differentiates us from others is that our developed AI Data Analytics Engine which comprehends raw data from the internet into easy-to-understand charts and graphs. It also calculates and provides fundamental charts such as ‘Price to Earning’, ‘Price to Sales’ and ‘Price to Book’ values for you from the raw data available on the internet. We also curate Financial Statements of various companies from verified sources. With us, you can also find a comparative analysis of any stock with the top competitors in the industry. All our subscribed users will be getting a daily newsletter covering all the top news feed of the day along with analytical data of the stocks included in his/her portfolio, and more importantly, these will get generated based on user preferences.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa3">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa3" href="#collapsea3">
                            How do I make my watchlist?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea3" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>To make your watchlist you need to log in with us. If you are a new user, create your login id first. Then go to Markets. Here on the search bar, start typing the organisation name. You would find suggestions in the drop-down. Select the one you are looking for. This will enlist the ticker on to the scratch pad. Bingo! You added a ticker to your watchlist. Add more tickers the same way which you want to track. Once you are done, you can save the list on to the Portfolio for future reference. Now, on selecting the ticker, you can read all latest news on or related to the organisation.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa4">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa4" href="#collapsea4">
                            I don’t have an account with you. Can I still read the news?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea4" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Yes, you can. But we will not be able to save your watchlist for future reference. Every time you want to read news about a ticker, you need to search for it. Also, you will be missing out on our signature daily newsletter generated by our AI engine, which includes performance analysis of stock saved in the portfolio. Our AI is designed to deliver top news along with analytical data of those stocks only which you include in your portfolio. Also, it gradually learns your preferences based on your usage and improves the quality of news it curates for you.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa5">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa5" href="#collapsea5">
                            Which all stock exchanges can I follow here?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea5" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Though it can be used to track news on any of the stock exchanges available on the global market, currently you can only track the stocks listed on US stock exchanges and also the most active tickers for Currency, Commodity and Index markets. We will soon be coming up with Indian stocks and gradually will cover all the major stock exchanges around the world.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa6">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa6" href="#collapsea6">
                            Where can I find current open, high, low and close price of the stocks?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea6" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Once you have selected a ticker, you can find the stock price under ‘Daily Update’’ tab.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa7">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa7" href="#collapsea7">
                            Why am I getting “No data available” message for some stocks under Daily Updates and Fundamental?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea7" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>This is because as of now we only do data analysis of stocks listed on US stock exchanges namely NASDAQ, NYSE and AMEX. The stocks you have selected must not be listed on them. If it does and you are still getting the error message, please bring it to our notice by sending a mail to us at <strong>admin@agrud.com </strong>along with the screen-shot of the page.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa8">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa8" href="#collapsea8">
                            Why there are no Daily Updates and Fundamental for some tickers?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea8" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Yes, as in those markets they are not applicable. But we are providing the 3 years price graph in the Daily Update tab for all of them.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa9">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa9" href="#collapsea9">
                            Can I download the information from your website?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea9" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>No, it cannot be downloaded, but you can share links on to your social media pages (Facebook, Twitter, LinkedIn) or email it anyone you want. If you would like to get our contents directly fed into your website or blog, we can help you with API integration. For such requirements please write to us at <strong>admin@agrud.com.</strong></p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa10">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa10" href="#collapsea10">
                            Why am I not finding my stocks on your search bar?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea10" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Currently, our search is designed to show stocks which are enlisted on US stock exchanges. The reason you did not find your stock may be because it is not listed in any of the US stock exchanges (NASDAQ, NYSE and AMEX). If otherwise, please write to us at <strong>admin@agrud.com</strong>.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa11">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa11" href="#collapsea11">
                            How secure is my personal information with you?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea11" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>Our commitment to the privacy and anonymity of our all registered members of the platform is deeply embedded in our DNA as a company. We go to great lengths to make sure that your personally identifying information will never be shared, sold, stolen, or otherwise mishandled. Read our <a href="https://www.marketanalyst.ai/privacy">Privacy policy</a> for more details.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa12">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa12" href="#collapsea12">
                            I have other questions not answered here.                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea12" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>We keep ourselves busy in making our product better. Hence, your feedback is an extremely important part of our product development process. So please don’t be shy about providing it (whether good, bad, or indifferent).<br />
                            For a partnership, general feedback or sales related queries, please write to us at <strong>admin@agrud.com</strong>. For, career related queries please reach out to us at<strong> hr@agrud.com</strong>.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa13">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa13" href="#collapsea13">
                            How do I reset my login password?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea13" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>You can only do so while you are logged-in. You can find ‘Password Reset’ button under your name on the top menu bar.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa14">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa14" href="#collapsea14">
                            How do I delete my Agrud Account?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea14" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>We would never want you to leave us. Please let us know why you want to delete your account with us by writing to us at <strong>admin@agrud.com</strong>. However, if you still want to get your account deleted, we will do so from our end and send you a confirmation mail.</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa15">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa15" href="#collapsea15">
                            What are the strengths of Agrud.com?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea15" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>1. We are free (and Bloomberg is not) and we intent to keep it this way.&nbsp;<br />
                            2. We are able to aggregate stories from multiple sources and bring it to you in one place<br />
                            3. The only place where you will get multiple analytics, competitor analysis on each stock and across sectors and industries<br />
                            (Replacement to Stock Screener)<br />
                            4. We think our EOD data and analytics is one of the best&nbsp;<br />
                            5. Email update on your favourite stocks everyday</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa16">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa16" href="#collapsea16">
                            What are the weaknesses of Agrud?                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea16" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>1. No live data&nbsp;<br />
                            2. No ETFs<br />
                            3. No Future Earnings projections<br />
                            &nbsp;(Working on all of them)</p>
                        </div>
                    </div>     
                    </div>
                </div>
                <div className="panel-group left-faq" id="faqa17">
                    <div className="panel panel-default">
                    <div className="panel-heading ">
                        <h4 className="panel-title ">
                        <a className="collapsed" data-toggle="collapse" data-parent="#faqa17" href="#collapsea17">
                            Other interesting features that we are coming up with soon                                      </a>
                        </h4>
                    </div>
                    <div id="collapsea17" className="panel-collapse collapse">
                        <div className="panel-body">
                        <p>1. Technical analysis&nbsp;<br />
                            2. Daily/Weekly top picks<br />
                            3. Sentiment Analysis</p>
                        </div>
                    </div>     
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        );
    }
}

export default Faq;
