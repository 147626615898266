import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Sidebar extends Component {
    render() {
      return (
        <div>
            <div className="popular-screeners" style={{width:"101.5%"}}>
                <div className="card-header" style={{height:"41px"}}>
                <i className="fa fa-signal card-title-icon custom-card-title"  style={{fontSize:"14px"}} />
                <p className="card-title" style={{marginTop:"-5px"}}>Popular Screeners</p>
                </div>
                <div id="consumers-list" style={{border: '1px solid rgba(210,214,222,1)'}}>
                <div className="static-cons-list">
                {
                    window.location.pathname !== '/charts' ?
                    <div>
                    <div className="con-item ">
                    <Link to="/small-cap" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Small Cap - Best Daily Performers      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/large-cap" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{lineHeight:"16px"}}>
                        Large Cap - Best Daily Performers - Financials      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/NASDAQ-most-active" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        NASDAQ - Most Active      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/automobiles-worst-performers" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Automobiles - Worst Performers      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/NASDAQ-52-week-high" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        NASDAQ - 52 Week High      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/small-cap-most-active-stocks" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Small Cap - Most Active Stocks      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/FAANG-stocks" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        FAANG Stocks      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/large-cap-best-daily-perfomance" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{lineHeight:"16px"}}>
                        Large Cap - Best Daily Performers - Consumer Discretionary      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/SP500-cheap-stocks" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        SP500 - Cheap Stocks - PE      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/mid-cap-best-weekly-perfomance" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{lineHeight:"16px"}}>
                        Mid Cap - Best Weekly Performers - Information Technology      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/large-cap-best-monthly-perfomance" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self"  style={{lineHeight:"16px"}}>
                        Large Cap - Best Monthly Performers - Real Estate      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/NASDAQ-highest-dividend-yield" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        NASDAQ - Highest Dividend Yield      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/SP500-best-daily-performers" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        SP500 - Best Daily Performers      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/SP500-highest-dividend-yield" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        SP500 - Highest Dividend Yield      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/SP500-best-yearly-perfomers" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        SP500 - Best Yearly Performers      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/tobacco-worst-perfomers" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Tobacco - Worst Performers      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/SP500-52-week-high" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        SP500 - 52 Week High      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/small-cap-cheap-stocks" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Small Cap - Cheap Stocks - PE      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/dow-30-stocks" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        DOW 30 stocks      
                    </Link>
                    </div>
                    <div className="con-item ">
                    <Link to="/warren-buffett-portfolio" className="consumer-link title-link" title="Small Cap - Best Daily Performers" target="_self" style={{marginTop:"-2px"}}>
                        Warren Buffett Portfolio      
                    </Link>
                    </div>

                    </div>
                    :
                    <div>
                        <div id="consumers-list" style={{ border: "1px solid rgba(210,214,222,1)",marginLeft: "-18px" }}>
                        <div className="static-cons-list">
                            <div className="accordion chart-accordian" id="1_accordian" data-id={1}>
                            <div
                                className="con-item collapsed"
                                data-toggle="collapse"
                                data-target="#Equity_collapse"
                                aria-expanded="false"
                                aria-controls="Equity_collapse"
                            >
                                <div className="container">
                                <div className="row" id="Equity">
                                    <div className="col-sm-10 pt-2 drop_key">Equity</div>
                                    <div className="col-sm-2">
                                    <span className="pull-right" style={{paddingRight:"18px"}}>
                                        <i
                                        className="fa fa-angle-down rotate-icon"
                                        style={{ marginTop: "3px" }}
                                        />
                                    </span>
                                    </div>
                                </div>
                                </div>
                            </div>{" "}
                            <div
                                data-id="Equity"
                                id="Equity_collapse"
                                className="val_key collapse"
                                aria-labelledby="1_heading"
                                data-parent="#1_accordian"
                                style={{}}
                            >
                                <div
                                id="Aerospace & Defense"
                                className="card-body equity_collapse drop_inner 1 chart-highlight active"
                                >
                                Aerospace &amp; Defense
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Air Freight & Logistics"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Air Freight &amp; Logistics
                                </div>
                                <hr className="m-0" />
                                <div id="Airlines" className="card-body equity_collapse drop_inner 1">
                                Airlines
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Auto Components"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Auto Components
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Automobiles"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Automobiles
                                </div>
                                <hr className="m-0" />
                                <div id="Banks" className="card-body equity_collapse drop_inner 1">
                                Banks
                                </div>
                                <hr className="m-0" />
                                <div id="Beverages" className="card-body equity_collapse drop_inner 1">
                                Beverages
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Biotechnology"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Biotechnology
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Building Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Building Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Capital Markets"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Capital Markets
                                </div>
                                <hr className="m-0" />
                                <div id="Chemicals" className="card-body equity_collapse drop_inner 1">
                                Chemicals
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Commercial Services & Supplies"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Commercial Services &amp; Supplies
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Communications Equipment"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Communications Equipment
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Construction & Engineering"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Construction &amp; Engineering
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Construction Materials"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Construction Materials
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Consumer Finance"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Consumer Finance
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Containers & Packaging"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Containers &amp; Packaging
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Distributors"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Distributors
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Diversified Consumer Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Diversified Consumer Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Diversified Financial Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Diversified Financial Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Diversified Telecommunication Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Diversified Telecommunication Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Electric Utilities"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Electric Utilities
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Electrical Equipment"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Electrical Equipment
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Electronic Equipment, Instruments & Components"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Electronic Equipment, Instruments &amp; Components
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Energy Equipment & Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Energy Equipment &amp; Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Equity Real Estate Investment Trusts (REITs)"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Equity Real Estate Investment Trusts (REITs)
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Food & Staples Retailing"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Food &amp; Staples Retailing
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Food Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Food Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Gas Utilities"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Gas Utilities
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Health Care Equipment & Supplies"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Health Care Equipment &amp; Supplies
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Health Care Providers & Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Health Care Providers &amp; Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Health Care Technology"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Health Care Technology
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Hotels, Restaurants & Leisure"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Hotels, Restaurants &amp; Leisure
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Household Durables"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Household Durables
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Household Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Household Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="IT Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                IT Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Independent Power and Renewable Electricity Producers"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Independent Power and Renewable Electricity Producers
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Industrial Conglomerates"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Industrial Conglomerates
                                </div>
                                <hr className="m-0" />
                                <div id="Insurance" className="card-body equity_collapse drop_inner 1">
                                Insurance
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Internet & Direct Marketing Retail"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Internet &amp; Direct Marketing Retail
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Internet Software & Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Internet Software &amp; Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Leisure Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Leisure Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Life Sciences Tools & Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Life Sciences Tools &amp; Services
                                </div>
                                <hr className="m-0" />
                                <div id="Machinery" className="card-body equity_collapse drop_inner 1">
                                Machinery
                                </div>
                                <hr className="m-0" />
                                <div id="Marine" className="card-body equity_collapse drop_inner 1">
                                Marine
                                </div>
                                <hr className="m-0" />
                                <div id="Media" className="card-body equity_collapse drop_inner 1">
                                Media
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Metals & Mining"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Metals &amp; Mining
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Mortgage Real Estate Investment Trusts (REITs)"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Mortgage Real Estate Investment Trusts (REITs)
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Multi-Utilities"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Multi-Utilities
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Multiline Retail"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Multiline Retail
                                </div>
                                <hr className="m-0" />
                                <div id="NA" className="card-body equity_collapse drop_inner 1">
                                NA
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Oil, Gas & Consumable Fuels"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Oil, Gas &amp; Consumable Fuels
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Paper & Forest Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Paper &amp; Forest Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Personal Products"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Personal Products
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Pharmaceuticals"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Pharmaceuticals
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Professional Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Professional Services
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Real Estate Management & Development"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Real Estate Management &amp; Development
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Road & Rail"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Road &amp; Rail
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Semiconductors & Semiconductor Equipment"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Semiconductors &amp; Semiconductor Equipment
                                </div>
                                <hr className="m-0" />
                                <div id="Software" className="card-body equity_collapse drop_inner 1">
                                Software
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Specialty Retail"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Specialty Retail
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Technology Hardware, Storage & Peripherals"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Technology Hardware, Storage &amp; Peripherals
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Textiles, Apparel & Luxury Goods"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Textiles, Apparel &amp; Luxury Goods
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Thrifts & Mortgage Finance"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Thrifts &amp; Mortgage Finance
                                </div>
                                <hr className="m-0" />
                                <div id="Tobacco" className="card-body equity_collapse drop_inner 1">
                                Tobacco
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Trading Companies & Distributors"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Trading Companies &amp; Distributors
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Transportation Infrastructure"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Transportation Infrastructure
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Water Utilities"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Water Utilities
                                </div>
                                <hr className="m-0" />
                                <div
                                id="Wireless Telecommunication Services"
                                className="card-body equity_collapse drop_inner 1"
                                >
                                Wireless Telecommunication Services
                                </div>
                                <hr className="m-0" />
                            </div>
                            <div
                                className="con-item collapsed outer_val"
                                id="Currency"
                                data-toggle="collapse"
                                data-target="#Currency_collapse"
                                aria-expanded="false"
                                aria-controls="Currency_collapse"
                            >
                                <div className="container">
                                <div className="row">
                                    <div className="col-sm-10 pt-2 drop_key">Currency</div>
                                    <div className="col-sm-2" />
                                </div>
                                </div>
                            </div>
                            <div
                                className="con-item collapsed outer_val"
                                id="Commodity"
                                data-toggle="collapse"
                                data-target="#Commodity_collapse"
                                aria-expanded="false"
                                aria-controls="Commodity_collapse"
                            >
                                <div className="container">
                                <div className="row">
                                    <div className="col-sm-10 pt-2 drop_key">Commodity</div>
                                    <div className="col-sm-2" />
                                </div>
                                </div>
                            </div>
                            <div
                                className="con-item collapsed outer_val"
                                id="Index"
                                data-toggle="collapse"
                                data-target="#Index_collapse"
                                aria-expanded="false"
                                aria-controls="Index_collapse"
                            >
                                <div className="container">
                                <div className="row">
                                    <div className="col-sm-10 pt-2 drop_key">Index</div>
                                    <div className="col-sm-2" />
                                </div>
                                </div>
                            </div>
                            </div>{" "}
                        </div>
                        </div>
                    </div>
                    }
                     
                </div>
                </div>
            </div>
        </div>
      );
    }
}

export default Sidebar;
