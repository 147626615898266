import React, { Component } from 'react';
import Scratchpad from './Scratchpad';
import Charts from './Charts'
class Myspace extends Component {
    render() {
        const tab_view = {
            height: "36px",
            fontSize:"14px"
        }
        return (
        <div>
            <section className="markets-middle">    
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <a className="nav-link active" style={tab_view} data-toggle="tab" href="#scratchpad">My Scratchpad</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " style={tab_view}  data-toggle="tab" href="#charts">Charts</a>
                        </li>
                        </ul>
                    </div>
                </div>
                <div className="tab-content">
                <div id="scratchpad" className="tab-pane fade active show">
                    <Scratchpad />
                </div>
                <div id="charts" className="tab-pane fade sp_charts ">
                    <Charts />
                </div>
                <div id="daily_charts" className="tab-pane fade">
                </div>
                <div id="hourly_charts" className="tab-pane fade">
                </div>
                </div>
            </section>
        </div>
        );
    }
}

export default Myspace;
