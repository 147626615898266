import React, { Component } from 'react';

class Adviser extends Component {
    render() {
        return (
        <div>
            <div className="container content-container pb-5" id="team">
                <div className="row justify-content-center">
                <h2>Team</h2>
                </div>
                <div className="row justify-content-center mb-1">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sayanta-basu.jpg" className="img-fluid" alt="Sayanta Basu " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sayanta Basu                           </div>
                    <div className="member-desig">
                        CEO                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sabyasachi-sengupta.jpg" className="img-fluid" alt="Sabyasachi Sengupta " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sabyasachi Sengupta                           </div>
                    <div className="member-desig">
                        COO                          </div>
                    </div>    
                </div>
                </div>
                <div className="row justify-content-center mb-1">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/joheb-abedin.jpg" className="img-fluid" alt="Joheb Abedin " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Joheb Abedin                           </div>
                    <div className="member-desig">
                        Manager (Digital Marketing)                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/tanima-biswas.jpg" className="img-fluid" alt="Tanima Biswas " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Tanima Biswas                           </div>
                    <div className="member-desig">
                        Manager (CEO's Office)                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/subhamoy-guha.jpg" className="img-fluid" alt="Subhamoy Guha " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Subhamoy Guha                           </div>
                    <div className="member-desig">
                        Software Engineer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sramana-sengupta.jpg" className="img-fluid" alt="Sramana Sengupta " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sramana Sengupta                           </div>
                    <div className="member-desig">
                        Software Engineer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/moumita-dey.jpg" className="img-fluid" alt="Moumita Dey " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Moumita Dey                           </div>
                    <div className="member-desig">
                        Associate Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/prince-kumar-chaturvedi.jpg" className="img-fluid" alt="Prince Kumar Chaturvedi " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Prince Kumar Chaturvedi                           </div>
                    <div className="member-desig">
                        Senior Research Analyst                          </div>
                    </div>    
                </div>
                </div>
                <div className="row justify-content-center mb-1">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/debajyoti-roy.jpg" className="img-fluid" alt="Debajyoti Roy " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Debajyoti Roy                           </div>
                    <div className="member-desig">
                        Senior Research Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/aniruddha-misra.jpg" className="img-fluid" alt="Aniruddha Misra " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Aniruddha Misra                           </div>
                    <div className="member-desig">
                        Business Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/shibaji-sarkar.jpg" className="img-fluid" alt="Shibaji Sarkar " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Shibaji Sarkar                           </div>
                    <div className="member-desig">
                        Graphic Designer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sangita-mukherjee.jpg" className="img-fluid" alt="Sangita Mukherjee " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sangita Mukherjee                           </div>
                    <div className="member-desig">
                        Admin and Research Associate                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/arnab-das.jpg" className="img-fluid" alt="Arnab Das " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Arnab Das                           </div>
                    <div className="member-desig">
                        Associate Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/tamal-dutta.jpg" className="img-fluid" alt="Tamal Dutta " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Tamal Dutta                           </div>
                    <div className="member-desig">
                        Digital Marketing Associate                          </div>
                    </div>    
                </div>
                </div>
                <div className="row justify-content-center mb-1">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/ankur-ghosh.jpg" className="img-fluid" alt="Ankur Ghosh " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Ankur Ghosh                           </div>
                    <div className="member-desig">
                        Digital Marketing Associate                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/soumodip-pramanik.jpg" className="img-fluid" alt="Soumodip Pramanik " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Soumodip Pramanik                           </div>
                    <div className="member-desig">
                        Research Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/shilpa-mazumder.jpg" className="img-fluid" alt="Shilpa Mazumdar " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Shilpa Mazumdar                           </div>
                    <div className="member-desig">
                        PHP Developer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/antan-goswami.jpg" className="img-fluid" alt="Antan Goswami " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Antan Goswami                           </div>
                    <div className="member-desig">
                        Associate Frontend Engineer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/nilotpal-guchhait.jpg" className="img-fluid" alt="Nilotpal Guchhait " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Nilotpal Guchhait                           </div>
                    <div className="member-desig">
                        Associate Software Engineer                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/nitesh-singh.jpg" className="img-fluid" alt="Nitesh Singh " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Nitesh Singh                           </div>
                    <div className="member-desig">
                        Manager (Accounts &amp; Compliance)                          </div>
                    </div>    
                </div>
                </div>
                <div className="row justify-content-center mb-1">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/partha-sarathi-sen.jpg" className="img-fluid" alt="Partha Sarathi Sen " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Partha Sarathi Sen                           </div>
                    <div className="member-desig">
                        Manager (Operations)                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/suman-maiti.jpg" className="img-fluid" alt="Suman Maiti " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Suman Maiti                           </div>
                    <div className="member-desig">
                        Admin Assistant                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sharafat-ali.jpg" className="img-fluid" alt="Sharafat Ali " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sharafat Ali                           </div>
                    <div className="member-desig">
                        Associate Research Analyst                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/raghav-khaitan.jpg" className="img-fluid" alt="Raghav Khaitan " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Raghav Khaitan                           </div>
                    <div className="member-desig">
                        Research Associate Intern                          </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sayan-datta.jpg" className="img-fluid" alt="Sayan Datta " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sayan Datta                           </div>
                    <div className="member-desig">
                        Digital Marketing Associate Intern                          </div>
                    </div>    
                </div>
                </div>
                <div className="row justify-content-center">
                <h2>Guidance &amp; Oversight</h2>
                </div>
                <div className="row justify-content-center">
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/deep-kapur.jpg" className="img-fluid" alt="Dr. Deep Kapur " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Dr. Deep Kapur                       </div>
                    <div className="member-desig">
                        Advisor                      </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sajid-mohamed.jpg" className="img-fluid" alt="Sajid Mohamed " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sajid Mohamed                       </div>
                    <div className="member-desig">
                        Director                      </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/anindya-dutta.jpg" className="img-fluid" alt="Dr. Anindya Dutta " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Dr. Anindya Dutta                       </div>
                    <div className="member-desig">
                        Advisor                      </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/sanjiv-aiyar.jpg" className="img-fluid" alt="Sanjiv Aiyar " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Sanjiv Aiyar                       </div>
                    <div className="member-desig">
                        Director                      </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/ajay-kashyap.jpg" className="img-fluid" alt="Ajay Kashyap " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Ajay Kashyap                       </div>
                    <div className="member-desig">
                        Advisor                      </div>
                    </div>    
                </div>
                <div className="portfolio-box mr-1">
                    <img src="https://www.marketanalyst.ai/assets/common/images/team/mark-chowdhry.jpg" className="img-fluid" alt="Mark Chowdhry " />
                    <div className="portfolio-txt-bg">
                    <div className="member-name">
                        Mark Chowdhry                       </div>
                    <div className="member-desig">
                        Advisor                      </div>
                    </div>    
                </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Adviser;
