import React, { Component } from 'react';
var loadScript = require('load-script')

// import {parseDate,getdata,getdata_json,setsidebarMenu,img_height,currentSlide,showSlides,loadChartImages,imageAdd,ImageUrlExists,regular_size,header_banner_js,load1,plusSlides,changechartinterval} from './../../../public/himanshu_chart'
// import Script from 'react-load-script'
// import ScriptTag from 'react-script-tag';
class Dailychart extends Component {
    constructor()
    {
      super()
      this.state={
        scriptLoaded:false
      }
      // setInterval(this.loadData, 1000);
    }
   
   
    render() {
       
      return (
        <div id="charts" className="tab-pane fade sp_charts active show">
        {/* <Script
          url="http://34.67.106.166:3000/himanshu_chart.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        /> */}
        {/* <Script
          url="http://34.67.106.166:3000/fabric.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        /> */}
        <img id="loading-image" src="https://www.marketanalyst.ai/assets/common/images/loader-small.gif" alt="" style={{display: 'none'}} />
        <link rel="stylesheet" type="text/css" href="https://www.marketanalyst.ai/chart_demo/asset/css/s_p_chartstyle.css" />
        <div className="col-md-12 main_cl col-xs-12 text-center bg-white pt-4" style={{marginTop: '20px'}}>
          <div className="side_menu" >
            <ul className="side_ul" style={{width: '650px'}}>
              <li className="tool" rel="tooltip" title="Draw Line">
                <img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_trend_line.png" className="main_img" alt="" />
                <ul>
                  <li id="tread_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_trend_line.png" alt="" /><span>Trend Line</span>
                  </li>
                  <li id="info_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_info.png" alt="" /><span>Info Line</span>
                  </li>
                  <li id="angle_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_tread.png" alt="" /><span>Trend Angle</span>
                  </li>
                  <li id="horizontal_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_horizontal_line.png" alt="" /><span>Horizontal Line</span>
                  </li>
                  <li id="horizontal_ray"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_horizon-ray.png" alt="" /><span>Horizontal Ray</span>
                  </li>
                  <li id="vertical_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_vertical_line.png" alt="" /><span>Vertical Line</span>
                  </li>
                  <li id="cross_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_cross.png" alt="" /><span>Cross Line</span>
                  </li>
                  <li id="arrowline"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_arrow.png" alt="" /><span>Arrow</span>
                  </li>
                  <li id="ray"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_ray.png" alt="" /><span>Ray</span>
                  </li>
                  <li id="extended_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_extend.png" alt="" /><span>Extended line</span>
                  </li>
                  <li id="parallel_channel"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_parral.png" alt="" /><span>Parallel Channel</span>
                  </li>
                  <li id="disjoint_line"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/l_disjoint.png" alt="" /><span>Disjoint Angle</span>
                  </li>
                </ul>
              </li>
              <li className="tool" id="editable_text" data-toggle="tooltip" title="Text"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/T.png" className="main_img" alt="" />
              </li>
              
              <li className="tool" data-toggle="tooltip" title="Draw Fib"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/p_fib.png" className="main_img" alt="" />
                <ul className="pitch">
                  <li id="fib_retracement"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/p_fib.png" alt="" /><span>Fib Retracement</span>
                  </li>
                  <li id="fib_projection"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/p_tread.png" alt="" /><span>Fib Projection</span>
                  </li>
                  <li id="fib_extension"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/p_tread.png" alt="" /><span>Fib Extension</span>
                  </li>
                </ul>
              </li>
              <li className="tool" id="snapshot" data-toggle="tooltip" title="Capture"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_snapshot.png" className="main_img" alt="" />
              </li>
              <li className="tool" data-toggle="tooltip" title="Pointer"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_pointer.png" id="main_cursor" className="main_img" alt="" />
                <ul>
                  <li id="arrow"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_pointer.png" alt="" /><span>Arrow</span>
                  </li>
                  <li id="cross"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_cross.png" alt="" /><span>Cross</span>
                  </li>
                  <li id="dot"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/Ellipic_dotse 2.png" alt="" /><span>Dot</span>
                  </li>
                </ul>
              </li>
              <li className="tool" id="bin" data-toggle="tooltip" title="Delete"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_remove.png" className="main_img" alt="" /></li>
              <li className="tool" id="eraser" data-toggle="tooltip" title="Eraser"><img src="https://www.marketanalyst.ai/chart_demo/asset/icon/ic_eraser.png" className="main_img" alt="" />
              </li>
            </ul>
          </div>
          
          <div id="slider_main" >
            <div className="hover_line outer_canvas">
              <div className="canvas-container" style={{width: '602px', height: '337px', position: 'relative', userSelect: 'none'}}>
                <canvas id="can" className="lower-canvas" width={602} height={337}/>
                {/* <canvas className="upper-canvas" width={602} height={337} /> */}
              </div>
              <div className="vertical"  />
              <div className="horizontal"  />
              <div className="xvalue" >2017-09-13</div>
              <div className="yvalue" >35.2</div>
            </div>
            <div id="slider">
              <div className="single-item">
                <div className="mySlides" id="chart-div-0" style={{ display: "block" }}>
                  <button
                    style={{
                      position: "absolute",
                      top: "40px",
                      left: "-45px",
                      fontSize: "20px",
                    }}
                    type="button"
                    className="btn prev"
                    data-parentid="Equity"
                    data-childid="Aerospace & Defense"
                    data-nextkey={1}
                  >
                    <span className="fa fa-chevron-left" />
                  </button>
                  <div className="slide_img">
                    <img
                      src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%2Fchart%2Fimages%2Fnyse_spr_ohlc.png"
                      alt=""
                    />
                  </div>
                  <div className="img_num">
                    <button
                      className="prev"
                      data-parentid="Equity"
                      data-childid="Aerospace & Defense"
                      data-nextkey={1}
                    >
                      <span className="fa fa-chevron-left" />
                    </button>
                    <p className="in_num">1 / 45</p>
                    <button
                      className="next"
                      data-parentid="Equity"
                      data-childid="Aerospace & Defense"
                      data-nextkey={1}
                    >
                      <span className="fa fa-chevron-right" />
                    </button>
                  </div>
                  <div className="full_expand">
                    <span className="fa fa-arrows-alt" />
                  </div>
                  <div className="tooltip_show" style={{ display: "none" }} />
                  <button
                    style={{
                      position: "absolute",
                      top: "40px",
                      right: "-45px",
                      fontSize: "20px",
                    }}
                    type="button"
                    className="btn next"
                    data-parentid="Equity"
                    data-childid="Aerospace & Defense"
                    data-nextkey={1}
                  >
                    <span className="fa fa-chevron-right" />
                  </button>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
}

export default Dailychart;