import React, { Component } from 'react';
class TermUse extends Component {
    constructor(props)
    {   
        super(props)
        this.state = {
           
        }
    }
    
    render() {
        return (
            <div>
                <div className="container privacy-terms" style={{marginTop:"52px"}}>
                    <div className="page-header cust-home">
                        <h1 style={{color:"#444444"}}>Terms of Use</h1>
                    </div>
                    <div className="row">
                        <div className="col-md-12 terms">
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>General Terms and Conditions</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            This website is operated by Agrud Technologies Pte Ltd (“we” or “us”),
                            on its own behalf and for itself and any affiliated companies or
                            businesses.&nbsp; The following terms and conditions&nbsp;apply to
                            your use of this website, as well as any services provided through the
                            Platform or any associated tools or other channels (including any
                            apps) provided by us. These Terms should be read alongside, and are in
                            addition to, our{" "}
                            <em>
                                <a href="https://marketanalyst.ai/privacy">Privacy Policy</a>
                            </em>{" "}
                            and our{" "}
                            <em>
                                <a href="https://marketanalyst.ai/service">
                                Terms and Conditions of Service
                                </a>
                            </em>
                            . In the event of any inconsistency between the Privacy Policy, these
                            Terms, and any separate written agreement between us, that agreement,
                            these Terms, and the Privacy Policy shall prevail, in that order.
                            </span>
                        </p>
                        <p>
                            <br />
                            <span style={{ fontSize: "14px" }}>
                            Please read these Terms carefully and print or save a copy for your
                            future reference. By using this website, you agree that you have read,
                            understood and agree to these Terms as amended from time to time. If
                            you do not agree to these Terms, you must stop using the Platform
                            immediately.
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>About us:</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            <strong>Agrud Technologies Pte Ltd</strong> is a company registered in
                            the Republic of Singapore.&nbsp;&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            Agrud is an international technology company that has developed
                            software which helps clients with several data and content related
                            services including search, analysis, processing and collation of data.
                            Should you have any questions about these Terms or wish to contact us
                            for any reason whatsoever, please use the contact details set out
                            below:
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            Post: 300 Tampines Ave 5 Tampines Junction Level 09­02 Singapore
                            529653.
                            <br />
                            Telephone: +65 67359465
                            <br />
                            E-mail:<strong> admin@agrud.com</strong>
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>Your use of the Platform</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            By using this Platform and/or creating an account, you confirm that
                            you are aged 18 or over. If you are not, you must not use this
                            Platform.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            You agree to use the Platform and any related services only for lawful
                            purposes and in a way which does not infringe the rights of any anyone
                            else or restrict or inhibit anyone else's use and enjoyment of the
                            Platform.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            You are permitted to download and print content from the Platform
                            solely for your own internal business purposes and/or personal use.
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>Intellectual property rights</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            As between you and us, we own all present and future copyright,
                            registered and unregistered trademarks, design rights, unregistered
                            designs, database rights and all other present and future intellectual
                            property rights and rights in the nature of intellectual property
                            rights existing in or in relation to the Platform ("IPR").
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            In particular, we claim intellectual property rights in our logo, as
                            well as our trademarks “Agrud”, “AgrudTech” and “Stella”, among
                            others. Subject to applicable law, you may not use that logo and/or
                            these terms or any other of our trademarks or trade dress outside of
                            the Platform without our express written permission. Except as legally
                            permitted, you may also not reproduce, sell, license or otherwise
                            exploit the Platform or any portion of the contents or data accessed
                            through the Platform without our express written permission,
                            especially if done on a commercial basis.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            If any IPR vests in you, whether by operation of law or otherwise, and
                            as far as you are able to create or share content on the Platform you
                            hereby assign to us all right, title and interest (whether legal or
                            beneficial) in such IPR throughout the world absolutely to the fullest
                            extent possible, including any and all renewals and extensions of such
                            IPR. To the extent you are unable to assign such rights to us, you
                            hereby grant to us a royalty-free, sub-licensable, assignable,
                            worldwide license to use any such IPR owned by you. To the fullest
                            extent possible under law, you unconditionally and irrevocably waive
                            any and all moral rights you may have either now or in the future
                            existing in or in relation to the Platform.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            You agree to execute and do all such deeds, documents, acts and things
                            as we may reasonably require in order to assign any IPR to us, to
                            carry out the intended purpose of these Terms, or to establish,
                            perfect, preserve or enforce our rights under these Terms.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            Product names, logos, brands, and trademarks mentioned on the Platform
                            may be owned by third parties and are property of their respective
                            owners. All company, product and service names used by us on the
                            Platform are for identification purposes only. Use of these names,
                            logos, brands, and trademarks does not imply endorsement of the
                            Platform or any content on the same.
                            <br />
                            <br />
                            We take intellectual property infringement very seriously. If you
                            believe that any content on our website or content shared through the
                            Platform infringes on your or another party’s intellectual property
                            rights, please contact us by sending an email to admin@agrud.com.
                            Please include in your email: (i) a description of the content you
                            believe to be infringing; (ii) where we can find the content; (iii)
                            the identity of the party you believe owns the relevant rights and
                            what rights you believe are being infringed; and (iv) your contact
                            information.
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>Our liability to you</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We exclude all representations, warranties, conditions and terms
                            express or implied by statute, common law or otherwise to the fullest
                            extent permitted by law. We accept no liability for any special,
                            indirect, incidental, consequential or economic loss howsoever caused
                            arising out of or in connection with your use of the Platform or any
                            information obtained from the same or from us.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We provide the Platform and related services in good faith but give no
                            warranty or representation that the content is accurate, complete or
                            up-to-date, nor that the Platform does not infringe the rights of any
                            third party.&nbsp;
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We may aggregate and analyse, but do not pre-screen any of the content
                            delivered to you through the Platform or your newsfeed. Unless and
                            except where expressly agreed by us in writing, we are not responsible
                            for the content of any news delivered to you or any party through your
                            newsfeed, including without limitation, responsibility for the
                            timeliness or accuracy of information, or the delivery of content
                            which you or any party may find offensive. We wish to specifically
                            inform you that certain information and content may be delayed in
                            delivery.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We accept no responsibility or liability for your use of content on
                            the Platform and such use is entirely at your own risk. In particular,
                            the services, information and content on the Platform are made
                            available for general information purposes only. These are not
                            intended as investment advice and must not be relied upon as
                            such.&nbsp; Any facts, views, advice, analyses, opinions and
                            recommendations expressed or made available are those of the
                            respective author(s). We cannot and do not endorse, and cannot be
                            responsible for, such facts, views, advice, analyses, opinions and
                            recommendations expressed. You agree not to rely solely on the content
                            on the Platform, but should seek independent advice as to financial,
                            investment, tax legal or other matters. You should also consider the
                            suitability of any investment for your own objectives.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            Additionally, our Platform may contain links to other Platforms. We
                            accept no responsibility or liability for any material supplied by or
                            contained on any third party Platform which is linked from or to this
                            Platform, or any use of personal data by such third party.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            While we take reasonable precautions to prevent the existence of
                            computer viruses and/or other malicious programs on the Platform, we
                            accept no liability for them.
                            <br />
                            Information transmitted via this Platform will pass over public
                            telecommunications networks, such as the internet. We make no
                            representation or warranty that the operation of this Platform will be
                            uninterrupted or error free and disclaim all liability in respect
                            thereof. We communicate information to users via electronic mail, and
                            we make no representation or warranty on behalf of the uninterrupted
                            or error free operation of your electronic mail service, or any other
                            third party, or that your email service will properly process our
                            communications.
                            </span>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            At the same time, you may not link to our Platform any site containing
                            inappropriate, profane, defamatory, infringing, obscene, indecent or
                            illegal topics, material or information that violates any applicable
                            intellectual property, proprietary, privacy or publicity rights, or is
                            otherwise deemed inappropriate, as determined by us in our sole
                            discretion. We may remove or disable any such links to our Platform at
                            any time.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We reserve the right to suspend your use of the Platform at any time
                            for operational, regulatory, legal or other reasons. We may terminate
                            your use of the Platform with immediate effect if you breach any of
                            these Terms.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            Nothing in these Terms shall limit our liability for personal injury,
                            death or fraud.&nbsp; Notwithstanding the above, our total liability
                            (whether in contract, tort, negligence or on any other basis) to you,
                            for any loss or damage shall be limited to any sums paid or payable to
                            us for any services, or the sum of one thousand Singapore Dollars,
                            whichever is greater.
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>Amendments to these Terms</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            We may update or amend these Terms from time to time to comply with
                            law or to meet our changing business requirements without notice to
                            you. Any updates or amendments will be posted on the Platform. By
                            continuing to use the Platform, you agree to be bound by the terms of
                            these updates and amendments.
                            <br />
                            These Terms were last updated on October 2019.
                            </span>
                        </p>
                        <p>
                            <strong>
                            <span style={{ fontSize: "14px" }}>Other important terms</span>
                            </strong>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            If any of these Terms are found to be illegal, invalid or
                            unenforceable by any court of competent jurisdiction, the rest of
                            these Terms shall remain in full force and effect.
                            <br />
                            Only you and we shall be entitled to enforce these Terms. No third
                            party shall be entitled to enforce any of these Terms.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontSize: "14px" }}>
                            These Terms together with our Privacy Policy and, if applicable, any
                            other written agreement with us, set out the entire agreement between
                            you and us and supersede any and all prior terms, conditions,
                            warranties and/or representations to the fullest extent permitted by
                            law. Any delay or failure by us to exercise any right we may have
                            under these Terms shall not constitute a waiver by us of that
                            right.&nbsp;
                            </span>
                        </p>
                        <h2>
                            <span style={{ fontSize: "14px" }}>
                            These Terms are governed by the laws of Singapore. Unless any
                            alternative dispute resolution procedure is agreed between the
                            parties, the parties&nbsp;agree to resolve any dispute which arises
                            out of or under this Agreement in the Courts of Singapore, to which
                            jurisdiction the parties hereby irrevocably submit. Before commencing
                            any proceedings in court, the parties will in good faith consider
                            mediation of any dispute.
                            </span>
                        </h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermUse ;
