import React, { Component } from 'react';
import axios from 'axios';
class Major_indicate extends Component {
  constructor()
  {
    super()
    this.state={
      day:'',
      month:'',
      year:'',
      get_data_detail:[],
      get_all_data:[],
      all_master_data:[],
      close_val:[],
      week_range:[],
      base_url:"http://35.184.152.222:9999/"
    }
  }

  componentDidMount()
  {

    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date();
    var dayName = days[d.getDay()];
    
    const date = new Date()
    date.setDate(date.getDate() - 1);
    var val = date.toString()
    var date_arr = val.split(' ');
    // if (date.getMonth() + 1 < 10) {
    //   this.state({current_month:"0"+date.getMonth() + 1})
    // }
    if (dayName === 'Sunday') {
      this.setState({day:date_arr[2]-1})
      if (date_arr[2]-1 < 10) {
        this.setState({currunt_date:"0"+date_arr[2]-1})
      }else{
        this.setState({currunt_date:date_arr[2]-1})
      }
    }
    if (dayName === 'Monday') {
      this.setState({day:date_arr[2]-2})
      if (date_arr[2]-2 < 10) {
        this.setState({currunt_date:"0"+date_arr[2]-2})
      }else{
        this.setState({currunt_date:date_arr[2]-2})
      }
    }else{
      this.setState({day:date_arr[2]})
      if (date_arr[2] < 10) {
      this.setState({currunt_date:"0"+date_arr[2]})
      }else{
        this.setState({currunt_date:date_arr[2]})
      }
    }
    this.setState({month:date_arr[1]})
    this.setState({year:date_arr[3]})


    axios.get(this.state.base_url+"get_master/?exchange=4&security_type=23&indicator_category=1&master_id=61821,61869,62384,63188,62870,63077,61870,63120,61871,63172&indicator_id=377,373,375,371,327,326,767,334,773,347,787,786,784,782,780,7,8,9,584,323,325,326,327&date_start=2020-10-23,00:00:00&date_end=2020-10-24,00:00:00&format=json", { 
    })
    .then(res => { 
        if(res.status === 200)
        {
          this.setState({get_data_detail:res.data})
        }
    })

    axios.get(this.state.base_url+"get_data/?exchange=4&security_type=23&indicator_category=1&master_id=61821,61869,62384,63188,62870,63077,61870,63120,61871,63172&indicator_id=377,373,375,371,327,326,767,334,773,347,787,786,784,782,780,7,8,9,584,323,325,326,327&date_start=2020-10-23,00:00:00&date_end=2020-10-24,00:00:00&format=json", { 
    })
    .then(res => { 
        if(res.status === 200)
        {
          var resp = res.data

          this.setState({get_all_data:resp})
          var unique_master = [];
          var all_val = [];
          for (let index = 0; index < resp.length; index++) {
              if (unique_master.indexOf(resp[index].master_id) > -1 === false) {
                unique_master.push(resp[index].master_id)
                all_val.push(resp[index])
              } 
            if (index === resp.length-1) {
              this.setState({
                get_all_data:[...this.state.get_all_data,...all_val]
             })
             
            }
          }
          var unique_master = [];
          var all_val = []
          for (let index = 0; index < this.state.get_all_data.length; index++) {
            if (unique_master.indexOf(this.state.get_all_data[index].master_id) > -1 === false) {
              unique_master.push(this.state.get_all_data[index].master_id)
              all_val.push(this.state.get_all_data[index])
            } 
            if (index === this.state.get_all_data.length-1) {
              // this.setState({all_master_data:all_val})
              this.setState({
                all_master_data:[...this.state.all_master_data,...all_val]
              })
            }
          }
      
        }
    })


    axios.get(this.state.base_url+"get_data/?exchange=4&security_type=23&indicator_category=1&master_id=61821,61869,62384,63188,62870,63077,61870,63120,61871,63172&indicator_id=377,373,375,371,327,326,767,334,773,347,787,786,784,782,780,7,8,9,584,323,325,326,327&date_start=2020-10-22,00:00:00&date_end=2020-10-23,00:00:00&format=json", { 
    })
    .then(res => { 
        if(res.status === 200)
        {
          var resp = res.data
          var close_value = []
          for (let index = 0; index < resp.length; index++) {
            if (resp[index].indicator_id === '371') {
              close_value.push(resp[index])
            } 
            if (index === resp.length-1) {
              this.setState({close_val:close_value})
            }
          }
        }
    })

    axios.get(this.state.base_url+"/get_data/?exchange=4&security_type=23&indicator_category=5&master_id=61821,61869,62384,63188,62870,63077,61870,63120,61871,63172&indicator_id=377,373,375,371,327,326,767,334,773,347,787,786,784,782,780,7,8,9,584,323,325,321&date_start=2020-08-10,00:00:00&date_end=2020-08-11,00:00:00&format=json", { 
    })
    .then(res => { 
      var resp = res.data
      var week_value = [];
      for (let index = 0; index < resp.length; index++) {
        if (resp[index].indicator_id === '323' || resp[index].indicator_id === '325' || resp[index].indicator_id === '767' ||
        resp[index].indicator_id === '334' || resp[index].indicator_id === '773' || resp[index].indicator_id === '347' || 
        resp[index].indicator_id === '787' || resp[index].indicator_id === '321') {
          week_value.push(resp[index])
        } 
        if (index === resp.length-1) {
          this.setState({week_range:week_value})
        }
      }
    })
  }

  get_symbol = (master_id) =>{
    const obj = Object.entries(this.state.get_data_detail)
    var all_master = [];
    obj.forEach(([key, value]) => 
     all_master.push({key,value})
    )
    for (let index = 0; index < all_master.length; index++) {
      if (all_master[index].value.master_id === master_id) {
         return all_master[index].value.symbol
      }
    }
  }

  get_chart = (master_id) =>{
    const obj = Object.entries(this.state.get_data_detail)
    var all_master = [];
    obj.forEach(([key, value]) => 
     all_master.push({key,value})
    )
    for (let index = 0; index < all_master.length; index++) {
      if (all_master[index].value.master_id === master_id) {
         return "https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%2Fchart%2Fimages%2Findex_"+all_master[index].value.symbol.toLowerCase()+"_ohlc.png"
      }
    }
  }

  get_name = (master_id) =>{
    const obj = Object.entries(this.state.get_data_detail)
    var all_master = [];
    obj.forEach(([key, value]) => 
     all_master.push({key,value})
    )
    for (let index = 0; index < all_master.length; index++) {
      if (all_master[index].value.master_id === master_id) {
         return all_master[index].value.name
      }
    }
  }

  get_change_value = (master_id) =>{
    for (let index = 0; index < this.state.get_all_data.length; index++) {
      if (this.state.get_all_data[index].indicator_id == "377" && this.state.get_all_data[index].master_id == master_id) {
          return this.state.get_all_data[index].value
      }
    }
  }

  get_day_range_first = (master_id) =>{
    for (let index = 0; index < this.state.get_all_data.length; index++) {
      if (this.state.get_all_data[index].indicator_id == "375" && this.state.get_all_data[index].master_id == master_id) {
          return this.state.get_all_data[index].value
      }
    }
  }

  get_day_range_secound = (master_id) =>{
    for (let index = 0; index < this.state.get_all_data.length; index++) {
      if (this.state.get_all_data[index].indicator_id == "373" && this.state.get_all_data[index].master_id == master_id) {
        return this.state.get_all_data[index].value
      }
    }
  }

  get_value = (master_id) =>{
    for (let index = 0; index < this.state.get_all_data.length; index++) {
      if (this.state.get_all_data[index].indicator_id == "371" && this.state.get_all_data[index].master_id == master_id) {
        return this.state.get_all_data[index].value
      }
    }
  }

  get_difference_value = (master_id,close_price)=>{
    for (let index = 0; index < this.state.close_val.length; index++) {
      if (this.state.close_val[index].master_id === master_id) {
        var previous = this.state.close_val[index].value;
        var price = close_price - this.state.close_val[index].value;
          var digit = (''+price)[1];
          if ((Math.round(price * 100) / 100).toFixed(2) > 0) {
            return "+"+(Math.round(price * 100) / 100).toFixed(2);
          }else{
            return (Math.round(price * 100) / 100).toFixed(2);
          }
      }
    }
    
  }

  get_difference_value_in_percentage = (master_id,close_price) => {
    for (let index = 0; index < this.state.close_val.length; index++) {
      if (this.state.close_val[index].master_id === master_id) {
        var previous = this.state.close_val[index].value;
        var price = close_price - this.state.close_val[index].value;


         var val = price / this.state.close_val[index].value * 100;
         if ((Math.round(val * 100) / 100).toFixed(2) > 0) {
          return "+"+(Math.round(val * 100) / 100).toFixed(2);
         }else{
          return (Math.round(val * 100) / 100).toFixed(2);
         }
         
      }
    }
  }

  get_difference_value_in_percentage_for_desc = (master_id,close_price) => {
    for (let index = 0; index < this.state.close_val.length; index++) {
      if (this.state.close_val[index].master_id === master_id) {
        var previous = this.state.close_val[index].value;
        var price = close_price - this.state.close_val[index].value;
         var val = price / this.state.close_val[index].value * 100;
          return (Math.round(val * 100) / 100).toFixed(2);
      }
    }
  }

  week_ragenge_low = (master_id) => {
    for (let index = 0; index < this.state.week_range.length; index++) {
      if (this.state.week_range[index].master_id === master_id && this.state.week_range[index].indicator_id === '325') {        
          return this.state.week_range[index].value;
      }
    }
  }

  week_ragenge_high = (master_id) => {
    for (let index = 0; index < this.state.week_range.length; index++) {
      if (this.state.week_range[index].master_id === master_id && this.state.week_range[index].indicator_id === '323') {        
          return this.state.week_range[index].value;
      }
    }
  }

  week52 = (master_id) =>{
    for (let index = 0; index < this.state.week_range.length; index++) {
      if (this.state.week_range[index].master_id === master_id && this.state.week_range[index].indicator_id === '321') { 
          return (Math.round(this.state.week_range[index].value * 100) / 100).toFixed(2); 
      }else{
        return '0.00'
      }
    }
  }
  get_close_date = (close_date) =>{
    var date = close_date.substring(5)
    return date.replace("-", "/");
  }
  get_exchange = (val) =>{
    return val
  }

  render() {
    var nf = new Intl.NumberFormat();
    return (
      <div>
         <div id="major-indices" className="container tab-pane news-block-container active show">
        <div className="analytics-header">
          <h1>Global Market Update</h1>
          <h1>as of Market Close: Oct 21st, 2020</h1>
        </div>
        <table className="block-table-analytics table" width="100%" cellSpacing={0} cellPadding={0} border={0}>
          <thead className="analytics-block-head-text">
            <tr>
              <th>Symbol</th>
              <th style={{paddingLeft:"74px"}}>Last Price</th>
              <th>Change</th>
              <th style={{width: '5px'}}>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="analytics-block">
          {
              this.state.all_master_data !== null ?
              this.state.all_master_data.map((item,index)=>(
                <React.Fragment key={index}>
                  <tr className="view index-name assets-table" id="index-indu-analytics" data-id={4094} data-type="F" data-uri="index-indu">
                    <td style={{paddingLeft: '6px', paddingTop: '6px', paddingBottom: '6px'}}>
                      <span className="screener-big-text" style={{paddingLeft: '2px'}}>
                        <a id="index-indu-ticker-big-text screener-big-text float-left" data-type="F" data-href="https://www.marketanalyst.ai/markets/index-indu/snapshots">{this.get_symbol(item.master_id)}</a>
                      </span>
                      <span title="Dow Jones Industrial Average" id="index-indu-ticker-small-text" className="ticker-small-text screener-small-text">{this.get_name(item.master_id)}</span>
                    </td>
                    <td style={{paddingTop: '6px', paddingBottom: '6px', textAlign: 'right', paddingRight: '122px'}} id="index-indu-ticker-last-price-analytics" className="index-indu-last-price-r ticker-last-price-analytics"><span style={{padding: '1px 3px',color:"#44474a"}} >{nf.format(item.value) }</span></td>
                    <td className="text-time text-align-right" style={{position: 'relative', padding: 0, paddingTop: '6px', paddingBottom: '6px', paddingRight: '6px'}}>
                      <span id="index-indu-change-text" className="index-indu-change ticker-last-price-change " style={{color: this.get_difference_value(item.master_id,item.value) > 0 ? "#2e914f" : "#ff333a" }}>{this.get_difference_value(item.master_id,item.value)}({this.get_difference_value_in_percentage(item.master_id,item.value) }%)</span>
                      <span id="index-indu-datetime" className="index-indu-close ticker-time-text margin-right-more" style={{right: '-28px'}}> Market Close {this.get_close_date(item.ts_date)}</span>
                    </td>
                    <td className="fancy-container border-top-0 border-right-0 pull-right">
                      <span className="fa fa-angle-down">
                      </span>
                    </td>
                  </tr>
                  <tr className="fold" id="index-indu">
                  <td colSpan={8} className="p-0 pt-2">
                    <div className="fold-content" id="index-indu-fold">
                      <div className="col-xs-12 news-block pad-bot-20 pt-0">
                        <div className="news-content">
                          <div title="Click to open index-indu - Snapshots page" className=" competitors-td0 competitors-tds" data-target="_self">
                            <div className="container_security_header">
                              <div className="security_header_content">
                                <div className="border-green index-indu-panel-border" />
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-3 col-xl-3 newscontent_left_panel">
                                      <span className="screener_header_name equity-name pull-left" data-uri="index-indu" title="Dow Jones Industrial Average">{this.get_name(item.master_id)}</span>
                                      <span className="screener_header_ticker pull-left ml-0 mr-3">{this.get_exchange(this.state.get_data_detail[index].exchange_code)}-{this.get_symbol(item.master_id)}</span>
                                      <span className="market_close_time index-indu-close d-block pull-left ml-0 mr-6"> Market Close {this.get_close_date(item.ts_date)}</span>
                                    </div>
                                    <div className="col-md-4 col-xl-4 newscontent_middle_panel pl-4 ">
                                      <span className="header_price index-indu-last-price">{nf.format( item.value)} </span>
                                      <span className="fund-tablink-price-currency"> </span>
                                      <span className="red-percent-text-fill index-indu-panel d-block bold-18 " style={{color: this.get_difference_value(item.master_id,item.value) > 0 ? "#54e31f" : "#f22424" }}>{this.get_difference_value(item.master_id,item.value)}({this.get_difference_value_in_percentage(item.master_id,item.value) }%) 
                                      {
                                        this.get_difference_value_in_percentage(item.master_id,item.value) < 0 ?
                                        <sup className="red">
                                        <i className="fa fa-sort-down" />
                                        </sup>
                                        :
                                        <sup className="green">
                                        <i className="fa fa-sort-up font-1" />
                                        </sup>
                                      }
                                    </span>
                                    </div>
                                    <div className="col-md-5 col-xl-5 newscontent_right_panel header_right_panel">
                                      <div className="row container_openprice">
                                        <div className="col-md-5 col-xl-4 container_openprice_left_txt">OPEN:</div>
                                        <div className="col-md-7 col-xl-8 container_openprice_right_price_value index-indu-open">{nf.format(this.get_change_value(item.master_id))}</div>
                                      </div>
                                      <div className="row container_openprice">
                                        <div className="col-md-5 col-xl-5 container_openprice_left_txt">DAY RANGE:</div>
                                        <div className="col-md-7 col-xl-7 container_openprice_right_price_value index-indu-range">{nf.format(this.get_day_range_first(item.master_id))} - {nf.format(this.get_day_range_secound(item.master_id))}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container du-container cont-full">
                          <div className="row row-eq-height">
                            <div className="col-12 marg-top-0 pr-0 pl-0">
                              <div className="du-chart-container du-ohlc">
                                <div className="consumer-chart">
                                  <div className="row px-3 pt-3 default">
                                    <div title="Click to open (index-indu)- Snapshots page" className="horizontal-middle pointer" style={{backgroundColor: '#fffaf7'}} data-title="3 Years Price Graph">
                                      <img alt="3 Years OHLC Graph (index-indu)" className="du-img du-chart-img-ohlc lazy analytics-image" data-src="https://www.marketanalyst.ai/get_image/index.php?i=http%3A%2F%2F35.184.152.222%2Fchart%2Fimages%2Findex_indu_ohlc.png" data-href="https://www.marketanalyst.ai/markets/index-indu/snapshots" src={this.get_chart(item.master_id)} />
                                    </div>
                                  </div>
                                  <div className="news-summary-css go-to-snapshots" title="Click to open index-indu snapshots" style={{color:"#000000"}}>
                                    <span className="news-summary" style={{fontSize:"12px",color:"#000000"}}>{this.get_name(item.master_id)} ({this.state.get_data_detail[index].exchange_code}:{this.get_symbol(item.master_id)}) opened on {this.state.month} {this.state.day}, {this.state.year} at {this.get_change_value(item.master_id)} and went marginally { this.get_difference_value_in_percentage(item.master_id,item.value) > 0 ? 'up' : 'down'  }  by { 
                                      this.get_difference_value_in_percentage_for_desc(item.master_id,item.value) 
                                      }%. The intraday price range was {this.get_day_range_first(item.master_id)} - {this.get_day_range_secound(item.master_id)} and eventually ended the session at {item.value}. It's 52-week range is {this.week_ragenge_low(item.master_id)} - {this.week_ragenge_high(item.master_id)}.</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></div></td>
                </tr>
                </React.Fragment>
            ))
            :null
          }
          </tbody>
        </table>
        </div>
    
      </div>
    );
  }
}

export default Major_indicate;
