import React, { Component } from 'react';
const axios = require('axios');

class Scratchpad extends Component {
    constructor(props)
    {
      super(props)
      this.state = {
        all_scratchpad:[],
        base_url:"http://localhost:4000/"
      }
    }

    componentDidMount()
    {
      axios.get(this.state.base_url+"get_scratchpad",  { 
      })
      .then(res => { 
        if(res.data.success === true)
        {
          this.setState({all_scratchpad:res.data.data.body})
        }
      })
    }

    render() {
      return (
        <div>
            <section className="analytics-details news-block-container">
                <div className="scratchpad-portfolio-header">
                <h1>My Scratchpad</h1>
                </div>
                <span className="my-change-wrapper d-none">
                <span title="Based on average calculation of daily price change" className="change_avg">
                    <i className="fa fa-calculator" aria-hidden="true" />
                </span>
                <span title="Based on average calculation of daily price change" className="change_avg_price avg_red_color" />                
                </span>
                <div className="mb-2 scratchpad-border d-none" />
                <div className="mt-4 empty-scratchpad">Empty Scratchpad</div>
            </section>
        </div>
      );
    }
}

export default Scratchpad;
