import React, { Component } from 'react';

class Currencies extends Component {
    constructor(props)
    {
        super(props)
        this.state={
            base_url:'http://35.184.152.222:9999/'
        }
    }

    componentDidMount()
    {
        // axios.get(this.state.base_url+"", { 
        // })
        // .then(res => { 
        // })
    }
    
    render() {
      return (
        <div style={{marginLeft: "-15px"}}>
            <div className="col-lg-12 pr-0 markets-middle">
                <section id="global-indices" className="global-indices">
                <div className="row pb-3">
                    <div className="created-by analytics-share big-cat ml-3 mt-2">
                    <span className="news-share-facebook action-btns-utils analytics-fb" >
                        <i className="fa fa-facebook" aria-hidden="true" />
                    </span>
                    <span className="news-share-twitter action-btns-utils analytics-twitter" >
                        <i className="fa fa-twitter" aria-hidden="true" />
                    </span>
                    </div>
                </div>		<div className="container-fluid pl-0 pr-0">
                    <div className="card">
                    <div className="card-body text-center analytics-card-body">
                        Currencies				</div>
                    </div>
                    <div className="row mr-0 ml-0">
                    <div className="col-sm-12 analytics-counts p-2"> 
                        <i className="fa fa-2x fa-info-circle" aria-hidden="true" />Showing 4  results              </div>
                    </div>
                    <div className="card-body p-0">
                    <div className="content-analytics-box">
                        <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                        <div className="analytics-content-img-box" style={{backgroundColor: '#d54a4e'}}>G</div>
                        </div>
                        <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                        <div className="news-header">
                            <span className="title-link">
                            <span className="news-source left-one-text">[ agrud.com ]</span>
                            </span>
                            <span className="news-title content-newsbox-heading">
                            <a target="_self" href="https://www.marketanalyst.ai/analytics/currencies/global-currencies" className="news-heading">
                                <span className="news-headings">Global Currencies</span>
                                <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                            </a>
                            </span>
                        </div>
                        <div className="news-content">
                            <div className="news-summary-wrapper">
                            <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Major Global Currency Pairs</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="content-analytics-box">
                        <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                        <div className="analytics-content-img-box" style={{backgroundColor: '#d76186'}}>A</div>
                        </div>
                        <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                        <div className="news-header">
                            <span className="title-link">
                            <span className="news-source left-one-text">[ agrud.com ]</span>
                            </span>
                            <span className="news-title content-newsbox-heading">
                            <a target="_self" href="https://www.marketanalyst.ai/analytics/currencies/american-currencies" className="news-heading">
                                <span className="news-headings">American Currencies</span>
                                <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                            </a>
                            </span>
                        </div>
                        <div className="news-content">
                            <div className="news-summary-wrapper">
                            <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Major American Currency Pairs</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="content-analytics-box">
                        <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                        <div className="analytics-content-img-box" style={{backgroundColor: '#41a4cc'}}>A</div>
                        </div>
                        <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                        <div className="news-header">
                            <span className="title-link">
                            <span className="news-source left-one-text">[ agrud.com ]</span>
                            </span>
                            <span className="news-title content-newsbox-heading">
                            <a target="_self" href="https://www.marketanalyst.ai/analytics/currencies/asia-pacific-currencies" className="news-heading">
                                <span className="news-headings">Asia Pacific Currencies</span>
                                <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                            </a>
                            </span>
                        </div>
                        <div className="news-content">
                            <div className="news-summary-wrapper">
                            <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Major Asia Pacific Currency Pairs</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="content-analytics-box">
                        <div className="col-sm-2 col-md-2 col-xs-4 mr-3 mobile-news-img-change analytics-img">
                        <div className="analytics-content-img-box" style={{backgroundColor: '#158380'}}>E</div>
                        </div>
                        <div className="col-sm-10 col-md-10 col-xs-7 space-reduce ">
                        <div className="news-header">
                            <span className="title-link">
                            <span className="news-source left-one-text">[ agrud.com ]</span>
                            </span>
                            <span className="news-title content-newsbox-heading">
                            <a target="_self" href="https://www.marketanalyst.ai/analytics/currencies/european-middle-eastern-african-currencies" className="news-heading">
                                <span className="news-headings">European, Middle Eastern &amp; African Currencies</span>
                                <i className="fa fa-fw fa-external-link" aria-hidden="true" />
                            </a>
                            </span>
                        </div>
                        <div className="news-content">
                            <div className="news-summary-wrapper">
                            <span style={{width: '60%'}} className="news-summary news-textsize-change analytics-desc">Major European, Middle Eastern &amp; African Currency Pairs</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
            </div>
        </div>
      );
    }
}

export default Currencies;
