import React, { Component } from 'react';
import { BrowserRouter as Router,Route,Link } from 'react-router-dom';
class Footer extends Component {
    render() {
      return (
     
        <div>
            <div className="row">
                <div className="col-sm-12">
                <div className="footer-logo" style={{marginLeft:"39px",marginTop: "4px"}}>
                    <img alt="footer-logo" width={170} height={35} src="https://www.marketanalyst.ai/assets/common/images/agrud-grey.png" />
                </div>
                <div className="share-media" style={{marginLeft:"30px"}}>
                    <ul>
                    <li className="share-facebook" style={{marginLeft:"8px"}}>
                        <span className="social-media-icon"><a target="_blank" href="https://www.facebook.com/agrudfintech/" className="fa fa-facebook fa-fw" /></span>
                    </li>
                    <li className="share-linkedin">
                        <span className="social-media-icon"><a target="_blank" href="https://www.linkedin.com/company/agrud" className="fa fa-linkedin fa-fw" /></span>
                    </li>
                    <li className="share-twitter">
                        <span className="social-media-icon"><a target="_blank" href="https://twitter.com/AgrudTech" className="fa fa-twitter fa-fw" /></span>
                    </li></ul>
                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 display-table-auto">
                <div className="footer-menu-container" style={{marginBottom:"-4px"}} >
                    <ul className="icon" style={{color:"#656565"}}>
                    <li><a className="about_agrud" >About Agrud</a></li>
                    <li><a className="teams-advisors" >Team &amp; Advisors</a></li>
                    <li><a className="article" >Articles</a></li>
                    <li><a className="life" >Life &amp; Careers</a></li>
                    <li><a className="faq" >FAQ</a></li>
                    <li><a className="term-of-use" >Terms of Use</a></li>
                    <li><a className="privacy-policy" >Privacy Policy</a></li>
                    <li><a className="service" >Service</a></li>
                    </ul>
                    <div className="clear-both" />
                </div>
                </div>
            </div>
            <div className="row pb-5">
                <div className="col-md-12">      
                <div className="footer-copyright-container">
                    © 2020 www.marketanalyst.ai – All Rights Reserved
                </div>
                </div>
            </div>
        </div>
     
      );
    }
}

export default Footer;
