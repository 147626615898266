import React, { Component } from 'react';
import moment from 'moment'
class News extends Component {
  constructor(props)
  {
    super(props)  

    this.state = {
      news_data:[],
      loadmore_section:1,
      loadmore_end:25,
      all_data:[],
      enddate:null,
      colour:'#2c4962',
      load_data:0,
      base_url:'http://35.184.152.222:9999/get_news/'
    }
  }

  componentDidMount() {
    var url = window.location.href;
    var url_arr = url.split("?");
    if (url_arr[2] !== undefined ) {
      var colour = url_arr[2].split("=");
      this.setState({colour:colour})
    }

    let today = new Date();
    var year = today.getFullYear();
    /* eslint-disable */
    var month_val = parseInt(today.getMonth()+1);
    var today_day = today.getDate()
    /* eslint-disable */
    if (month_val < 10) {
      var month = "0"+parseInt(today.getMonth()+1);
    }else{
      /* eslint-disable */
      var month = parseInt(today.getMonth()+1);
    }
    if (today_day < 10) {
      var day = "0"+today.getDate();
    }else{
      /* eslint-disable */
      var day = today.getDate();
    }
    let enddate= year + "-"+ month +"-"+ day;
    this.setState({enddate:enddate})
    fetch(this.state.base_url+"?date_start=2020-07-01,00:00:00&date_end="+enddate+",23:00:00&portfolio=60&page=1,25",{ method:"Get",headers:{'Accept':'*/*','Accept-Encoding':'gzip, deflate','Accept-Language':'en-US,en;q=0.9,hi;q=0.8,gu;q=0.7','Connection':'keep-alive','Host':'35.184.152.222:9999','Origin':'http://htest2.agrud.org'}}).then((data)=>{
      data.json().then((resp)=>{
          this.setState({
            news_data:[...this.state.news_data,...resp]
          })
      })
    })
  }
  setUser = ({user, isUser})=>{

  }
  loadmore = ()=>{
    this.setState({load_data:1})
    this.setState({loadmore_section:this.state.loadmore_section+1})
    this.setState({loadmore_end:this.state.loadmore_end+25})
    var section = this.state.loadmore_section+1;
    var end = this.state.loadmore_end+25;
    let enddate=this.state.enddate;
    fetch(this.state.base_url+"?date_start=2020-07-01,00:00:00&date_end="+enddate+",23:00:00&portfolio=60&page="+section+","+end,{ method:"Get",headers:{'Accept':'*/*','Accept-Encoding':'gzip, deflate','Accept-Language':'en-US,en;q=0.9,hi;q=0.8,gu;q=0.7','Connection':'keep-alive','Host':'35.184.152.222:9999','Origin':'http://htest2.agrud.org'}}).then((data)=>{
      data.json().then((resp)=>{
          this.setState({
            news_data:[...this.state.news_data,...resp]
          })
          this.setState({load_data:0})
      })
    })
  }

  get_date = (date)=>
  {
    var stillUtc = moment.utc(date).toDate();
    var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');

    var val = moment(local).fromNow() 
    if (val === 'a day ago') {
      return '1 day ago';
    }else if(val === 'an hour ago'){
      return '1 hour ago';
    }else{
      return val;
    }
  }

  from_url = (data) =>{
    var arr = data.split("/");
    var result = arr[2]
    var arr2 = result.split(".");
    if (arr2[0] === 'www') {
      return arr2[1]
    }else{
      return arr2[0]
    }
    
  }

  render() {
    return (
      <div id="news" className="container tab-pane active">
      {
          this.state.news_data.map((item,index)=>(
          <div className="row content-newsbox"  key={index}>
            <div className="col-sm-12 col-md-12 col-xs-7 space-reduce ">
              <div className="news-header news-bottom-gape">
                <span className="news-source right-one-text">[{ this.get_date(item.publish_date) }]</span>
                <span className="news-title content-newsbox-heading">
                  <a
                   target="_blank" href={item.url} style={{color:"#2c4962"}}
                    className="news-heading"
                    data-url={item.url}
                  >
                    <span className="news-headings" style={{color:this.state.colour}}>
                      {item.title}
                    </span>
                  </a>
                </span>
                <span className="sourced">
                  Story sourced from: <strong>{ this.from_url(item.url) }</strong>
                </span>
              </div>
              <div className="news-content">
                <div className="news-summary-wrapper">
                  <span className="news-summary news-textsize-change shorten">
                  {item.body}
                    <a
                      target="_blank"
                      className="text-primary"
                      href={item.url}
                    >
                      ...<i style={{color:"#007BFF",fontSize:"10px"}}>See Summary</i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          ))
      }
      <div className="news-load mb-3">
        <button
          className="agrud-button agrud-button-round load-more mt-3"
          onClick={()=>this.loadmore()}
          style={{backgroundColor:"#00A3E4",paddingTop:"2px"}}
        >
          {
            this.state.load_data === 0 ?
            'Load More News'
            :null
          }
          
        </button>
        {
            this.state.load_data === 1 ?
              <span id="old_news" className="loader" >
                <img alt="" src="https://www.marketanalyst.ai/assets/common/images/ajax-loader-white-horizontal.gif" />
              </span>
              :null
        }
      </div>
    </div>
    );
  }
}

export default News;