import React, { Component } from 'react';
class Service extends Component {
    constructor(props)
    {   
        super(props)
        this.state = {
           
        }
    }
    
    render() {
        return (
            <div className="container service-terms" style={{marginTop:"52px"}}>
            <div className="page-header cust-home">
              <h1 style={{color:"#444444"}}>Terms and Conditions of Service</h1>
            </div>
            <div className="row">
              <div className="col-md-12 service">
                <p>
                  These<strong> Terms and Conditions of Service</strong> ("
                  <strong>Terms of Service</strong>") apply whenever you order any Agrud
                  newsfeed curation and delivery services ("<strong>Service</strong>")
                  through our website platform (the "Platform") or otherwise from us.
                  These Terms of Service should be read alongside, and are in addition to,
                  our{" "}
                  <a href="https://marketanalyst.ai/terms">
                    General Terms and Conditions
                  </a>{" "}
                  ("<strong>Terms of Use</strong>") and our{" "}
                  <a href="https://marketanalyst.ai/privacy">Privacy Policy</a>.
                </p>
                <p>
                  Please read these Terms of Service carefully and print or save a copy
                  for your future reference. By ordering a Service from us, you agree that
                  you have read, understood and agree to these Terms of Service, the
                  Privacy Policy and Terms (each as amended from time to time). If you do
                  not agree to these Terms of Service, you must not order any Service from
                  us.
                </p>
                <ol>
                  <li>
                    <p>
                      <strong>About us</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          Agrud Technologies Pte Ltd. (referred to in these Terms of
                          Service as “we”, or “us”) is a company registered in Singapore,
                          whose address is at 300 Tampines Ave 5 Tampines Junction Level
                          09­02 Singapore 529653.
                        </p>
                      </li>
                      <li>
                        <p>
                          Our Service allows users to create, curate, and deliver,
                          specialized newsfeed.
                        </p>
                      </li>
                      <li>
                        <p>
                          Should you have any questions about these Terms of Service or
                          wish to contact us for any reason whatsoever, please use the
                          contact details set out below:
                          <br />
                          Post: 300 Tampines Ave 5 Tampines Junction Level 09­02 Singapore
                          529653. Telephone-&nbsp; +65 66795678.&nbsp; E-mail :
                          admin@agrud.com
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>About you</strong>
                      <br />
                      By ordering any Services from us, you confirm that you are:
                    </p>
                    <ol>
                      <li>
                        <p>over 18 years old; and</p>
                      </li>
                      <li>
                        <p>
                          residing in a jurisdiction where it is not prohibited by law to
                          offer or use our Services (It is your responsibility to ensure
                          that you are legally allowed to use our Services where you are
                          located).
                          <br />
                          If any of the above is incorrect, you must not order any Service
                          from this Platform and we reserve the right, in our sole
                          discretion, to refuse or accept your order.
                          <br />
                          If you are ordering Services on behalf of a company,
                          corporation, government body or any other type of non-natural
                          person, you confirm that you are authorized to transact business
                          and enter into binding agreements on its behalf.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Service descriptions</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          All Service descriptions on the Platform are provided in good
                          faith but are intended as guidance only and actual Services may
                          vary.
                        </p>
                      </li>
                      <li>
                        <p>
                          All prices shown on the Platform are in Singapore Dollar and
                          [inclusive/exclusive] of Goods and Services Tax (“GST”) at the
                          relevant rate.
                        </p>
                      </li>
                      <li>
                        <p>
                          Whilst we use reasonable endeavours to keep prices up-to-date on
                          the Platform, we reserve the right to alter prices at any time.
                          Any change in price will be communicated to you prior to
                          delivery of the relevant Services.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Intellectual property</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          You acknowledge that, as between you and us, we own all present
                          and future copyright, registered and unregistered trademarks,
                          design rights, unregistered designs, database rights and all
                          other present and future intellectual property rights and rights
                          in the nature of intellectual property rights existing in or in
                          relation to the Services ("IPR").
                        </p>
                      </li>
                      <li>
                        <p>
                          If any IPR vests in you, whether by operation of law or
                          otherwise, and as far as the Service allows you to create or
                          share content on the Platform or through the Service, you hereby
                          assign to us all right, title and interest (whether legal or
                          beneficial) in such IPR throughout the world absolutely to the
                          fullest extent possible, including any and all renewals and
                          extensions of such IPR. You unconditionally and irrevocably
                          waive any and all moral rights you may have either now or in the
                          future existing in or in relation to the Services. You also
                          agree to execute and do all such deeds, documents, acts and
                          things as we may reasonably require in order to assign any IPR
                          to us, to carry out the intended purpose of these Terms of
                          Service or to establish, perfect, preserve or enforce our rights
                          under these Terms of Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          The content shared with you through the Service may include
                          content in which third parties have intellectual property
                          rights, including without limitation, copyrights, trademarks,
                          and service marks. You agree not to use the Service to violate
                          the intellectual property rights of third parties, and we
                          specifically disclaim any liability for any violation on your
                          part of any intellectual property right of any third party.
                        </p>
                      </li>
                      <li>
                        <p>
                          The names “Agrud”, “AgrudTech” and “Stella” are trademarks of
                          Agrud Technologies Pte Ltd., and your use of the Service does
                          not grant to you any right to use these terms or any other
                          trademark or trade dress of Agrud Technologies Pte Ltd. without
                          its express written permission.
                        </p>
                      </li>
                      <li>
                        <p>
                          We take copyright infringement very seriously. If you believe
                          that any content on our website or content shared through the
                          Platform infringes on your or another party’s intellectual
                          property rights, please contact us by sending an email to
                          admin@agrud.com. Please include in your email: (i) a description
                          of the content you believe to be infringing; (ii) where we can
                          find the content; (iii) the identity of the party you believe
                          owns the copyright; (iv) your contact
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Service delivery</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          The delivery of our Services depends on services of third
                          parties including international and local telecom companies, and
                          we make no warranty or representation as to the operation of
                          these third parties and bear no liability for any failure or
                          omission on the part of these or any third parties.
                        </p>
                      </li>
                      <li>
                        <p>
                          We are not liable for any failure of the Service, including
                          without limitation: disruptions, untimely delivery, scheduled or
                          unscheduled maintenance, or any other reason due to action or
                          inaction by us or any third party which may impair your access
                          to the Platform or the delivery of the Service temporarily or
                          permanently.
                        </p>
                      </li>
                      <li>
                        <p>
                          We provide an aggregation service, and we do not pre-screen any
                          of the news delivered on your feed. We are not responsible for
                          the content of any news delivered to you or any party through
                          your newsfeed, including without limitation, responsibility for
                          the timeliness or accuracy of information, or the delivery of
                          content which you or any party may find offensive.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>User accounts</strong>
                    </p>
                    <ol>
                      <li>
                        <p>Our Platform allows you to create user accounts.</p>
                      </li>
                      <li>
                        <p>
                          You must provide true, accurate, up-to-date and complete
                          information in all fields indicated as mandatory when creating
                          and updating an account.
                        </p>
                      </li>
                      <li>
                        <p>
                          You are solely responsible for all activities that occur on or
                          under the account. We are not responsible for any unauthorized
                          access to our account.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Payment for the Services</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          No Services will be provided until we have received payment in
                          full for those Services.
                        </p>
                      </li>
                      <li>
                        <p>
                          During the checkout process, you will be asked to complete your
                          payment details. All highlighted fields must be completed. [We
                          accept most major credit and debit cards]. Please note that we
                          will collect, store and use your information in accordance with
                          our Privacy Policy.
                        </p>
                      </li>
                      <li>
                        <p>
                          All card payments are subject to authorisation by your card
                          issuer. If your payment is not authorised, we will cancel your
                          order and notify you in writing (which may include email) that
                          we have done so.
                        </p>
                      </li>
                      <li>
                        <p>
                          If we cannot supply you with the Services that you have ordered,
                          we will cancel your order and inform you of this as soon as
                          reasonably possible. We will give you a full refund where you
                          have already paid for the Services.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Delivery of the Services</strong>
                      <br />
                      All Services shall be delivered electronically through our Platform.
                      <br />
                      &nbsp;
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Our liability to you</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          We will use reasonable skill and care in fulfilling any order
                          placed by you which is accepted by us. However, we exclude all
                          other representations, warranties, conditions and terms
                          expressed or implied by statute, common law or otherwise to the
                          fullest extent permitted by law.
                        </p>
                      </li>
                      <li>
                        <p>
                          Save as expressly set out in these Terms of Service, we will not
                          be liable for any special, indirect, incidental, consequential
                          or economic loss or for loss of profits or revenues howsoever
                          caused arising in connection with any order placed by you.
                        </p>
                      </li>
                      <li>
                        <p>
                          Notwithstanding the above, our total liability (whether in
                          contract, tort, negligence or on any other basis) to you, for
                          any loss or damage shall be limited to the sums paid or payable
                          for the Services.
                        </p>
                      </li>
                      <li>
                        <p>
                          We will not be liable to you where we breach these Terms of
                          Service due to any cause that is beyond our reasonable control,
                          including acts of God, explosions, floods, tempests, fires or
                          accidents; wars or threats of war, sabotage, insurrection, civil
                          disturbance or requisition; acts, restrictions, regulations,
                          prohibitions or measures of any kind on the part of any
                          governmental authority; import or export regulations or
                          embargoes; strikes, lock-outs or other industrial actions, or
                          trade disputes; difficulties in obtaining materials, labour,
                          fuel, parts or machinery; power failure or breakdown in
                          machinery.
                        </p>
                      </li>
                      <li>
                        <p>
                          Nothing in these Terms shall limit our liability for personal
                          injury, death or fraud.
                        </p>
                      </li>
                      <li>
                        <p>
                          This clause does not affect your statutory rights as a consumer.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>No Returns and Refunds</strong>
                    </p>
                    <ol>
                      <li>
                        <p>All Sales are final.</p>
                      </li>
                      <li>
                        <p>
                          In limited cases, you may be able to cancel your order with us
                          before the Services are delivered to you. If you cancel your
                          order in such circumstances, we will refund any money paid by
                          you.
                          <br />
                          &nbsp;
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      <strong>Other important terms</strong>
                    </p>
                    <ol>
                      <li>
                        <p>
                          We may update or amend these Terms of Service from time to time
                          to comply with the law or to meet our changing business
                          requirements without notice to you. Any updates or amendments
                          will be posted on the Platform.
                        </p>
                      </li>
                      <li>
                        <p>
                          These Terms of Service supersede any other terms and conditions
                          previously published by us and any other representations or
                          statements made by us to you, whether oral, written or
                          otherwise.
                        </p>
                      </li>
                      <li>
                        <p>
                          You may not assign or subcontract any of your rights or
                          obligations under these Terms of Service to any third party
                          unless we agree in writing.
                        </p>
                      </li>
                      <li>
                        <p>
                          We may assign, transfer or subcontract any of our rights or
                          obligations under these Terms of Service to any third party at
                          our discretion.
                        </p>
                      </li>
                      <li>
                        <p>
                          No relaxation or delay by us in exercising any right or remedy
                          under these Terms of Service shall operate as a waiver of that
                          right or remedy or shall affect our ability to subsequently
                          exercise that right or remedy. Any waiver must be agreed by us
                          in writing.
                        </p>
                      </li>
                      <li>
                        <p>
                          If any of these Terms of Service are found to be illegal,
                          invalid or unenforceable by any court of competent jurisdiction,
                          the rest of these Terms of Service shall remain in full force
                          and effect.
                        </p>
                      </li>
                      <li>
                        <p>
                          Only you and we shall be entitled to enforce these Terms of
                          Service. No third party shall be entitled to enforce any of
                          these Terms of Service.
                        </p>
                      </li>
                      <li>
                        <p>
                          These Terms of Service are governed by Singapore. In the event
                          of any matter or dispute arising out of or in connection with
                          these Terms of Service, you and we shall submit to the exclusive
                          jurisdiction of the&nbsp; Singapore courts.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        );
    }
}

export default Service ;
