import React, { Component } from 'react';
import Sidebar from './Sidebar'
import Myspace from './../Myspace/Myspace'
import Market from './../market/Market'
import Article from './../articles/Articles'
import Aboutus from './../about/About'
import Usequities from './../analytics/Us_equities'
import Globalindices from './../analytics/Global_indices'
import Currencies from './../analytics/Currencies'
import Charts from './../charts/Charts'
import Screeners from './../screeners/Screeners'
import News from './../news/News'
import TermUse from './../service/TermUse'
import Privacy from './../service/Privacy'
import Service from './../service/Service'
import ReactFormInputValidation from "react-form-input-validation";
import axios from 'axios';
import '../../component/style_toastr.css';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
let url = 'http://54.85.225.109:4000/ticker/';
var CryptoJS = require("crypto-js");

import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show_sidebar: true,
            active_tab: '',
            show_login_modal: false,
            remember_val:0,
            fields: {
                email: "",
                password: "",
                remember:"",
            },
            errors: {},
            first_name: "",
            last_name: "",
            email:"",
            password:"",
            password_confirm:"",
            first_name_error:"",
            last_name_error:"",
            email_error:"",
            password_error:"",
            password_confirm_error:"",
            login_email:null,
            login_pass:null,
            // base_url:"https://www.marketanalyst.ai/",
            base_url:"http://localhost:4000/",
        }
        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            email: "required|email",
            password: "required",
            

        });

       
        this.form.onformsubmit = (fields) => {
            var data = {
                identity:fields.email,
                password:fields.password,
                remember:this.state.remember_val,
                csrf_value:''
            } 
            axios.post(this.state.base_url+"login", data, { 
            })
            .then(res => { 
                if(res.data.success === true)
                {
                    closemodal()
                    toastr.options = {
                    positionClass : 'toast-top-full-width',
                    timeOut: 8000,
                    closeButton:true
                    }
                    toastr.clear()
                    setTimeout(() => toastr.success('Login Successful'), 100)
                    var password_val = CryptoJS.AES.encrypt(fields.password, 'secret key 123').toString();
                    var login_val = {
                        identity:fields.email,
                        pass:password_val
                    }
                    localStorage.setItem('login',JSON.stringify(login_val));
                    this.componentDidMount()
                }else{
                    closemodal()
                    toastr.options = {
                    positionClass : 'toast-top-full-width',
                    timeOut: 2000,
                    closeButton:true
                    }
                    toastr.clear()
                    setTimeout(() => toastr.error('Login not Successful'), 100)
                }
            })
        }
        const interval = setInterval(() => {
            this.check_url()
          }, 1000);
    }
    
    componentDidMount()
    {
        var login = JSON.parse(localStorage.getItem('login'));
        if(login !== null)
        {
            var bytes  = CryptoJS.AES.decrypt(login.pass, 'secret key 123');
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            
            this.setState({login_email:login.identity})
            this.setState({login_pass:login.identity})
        }
    }

    sign_out =()=>{
        localStorage.clear();
        this.setState({login_email:null})
        this.setState({login_pass:null})
    } 

    check_url = () =>{
        var url = window.location.pathname.replace("/","");
        if (url !== 'market' && url !== 'us-equities' && url !== 'global-indices' && url !== 'currencies'
        && url !== 'article' && url !== 'aboutus' && url !== 'myspace' && url !== 'Charts') {
            this.setState({active_tab:'global-indices'})
        }
    }

    selected_nav = (val) => {
        if (val === 'aboutus') {
            this.setState({ show_sidebar: false })
            this.setState({ active_tab: val })
        } else {
            this.setState({ show_sidebar: true })
            this.setState({ active_tab: val })
        }

    }

    set_remember = () =>{
        if(this.state.remember_val === 0)
        {
            this.setState({remember_val:1})
        }else{
            this.setState({remember_val:0})
        }
    }

    show_login_modal = () => {
        this.setState({ show_login_modal: true })
    }

    close_login_modal = () => {
        this.setState({ show_login_modal: false })
    }

    set_firstname = (e)=>{
        this.setState({first_name:e.target.value})
    }

    set_lastname = (e)=>{
        this.setState({last_name:e.target.value})
    }

    set_email = (e)=>{
        this.setState({email:e.target.value})
    }

    set_password = (e)=>{
        this.setState({password:e.target.value})
    }

    set_password_confirm = (e)=>{
        this.setState({password_confirm:e.target.value})
    }

    valid()
	{
		if (this.state.first_name === '' || this.state.first_name === null) 
		{
			this.setState({first_name_error:"Firstname must be required"})
		}else{
            this.setState({first_name_error:""})
        }
		if (this.state.last_name === '' || this.state.last_name === null) 
		{
			this.setState({last_name_error:"Lastname must be required"})
		}else{
            this.setState({last_name_error:""})
        }
        if (this.state.email === '' || this.state.email === null) 
		{
			this.setState({email_error:"Email must be required"})
		}else{
            this.setState({email_error:""})
        }
        if (this.state.password === '' || this.state.password === null) 
		{
			this.setState({password_error:"Password must be required"})
		}else{
            this.setState({password_error:""})
        }
        if (this.state.password_confirm === '' || this.state.password_confirm === null) 
		{
			this.setState({password_confirm_error:"Confirm password must be required"})
		}else{
            if (this.state.password_confirm !== this.state.password) {
                this.setState({password_confirm_error:"Password And Confirm password not match"})

            }else{
                this.setState({password_confirm_error:""})
            }
        }
        if (this.state.first_name !== '' && this.state.last_name !== '' && this.state.email !== '' && this.state.password !== ''
            && this.state.password_confirm !== '' && this.state.password_confirm_error === "") {
            return true
        }

	}

    register = () =>{
        if(this.valid())
        {
            var data = {
                first_name:this.state.first_name,
                last_name:this.state.last_name,
                email:this.state.email,
                password:this.state.password,
                password_confirm:this.state.password_confirm
            }

            axios.post(this.state.base_url+"register", data,{ 
            })
            .then(res => { 
                if(res.data.success === true)
                {
                    closemodal()
                   toastr.options = {
                    positionClass : 'toast-top-full-width',
                    timeOut: 2000,
                    closeButton:true
                    }
                    toastr.clear()
                    setTimeout(() => toastr.success('Register Successful Verification Code has been Sent in Your Email'), 100)
                }else{
                    closemodal()
                   toastr.options = {
                    positionClass : 'toast-top-full-width',
                    timeOut: 2000,
                    closeButton:true
                    }
                    toastr.clear()
                    setTimeout(() => toastr.error('Register Not Successful'), 100)
                }
            })
        }
    }

    render() {

        return (
            <div>
                <Router>
                    {
                        window.location.pathname !== '/news' ?
                            <nav className="navbar navbar-expand-sm bg-custom" style={{ height: "44px" }}>
                                <a className="navbar-brand comp-brand" href="https://www.marketanalyst.ai/" />
                                <a className="mobile-trigger" href="#"><i className="fa fa-bars" /></a>
                                <ul className="navbar-nav mobile-menu" >
                                    <Link to="/market" style={{ color: this.state.active_tab === 'market' || window.location.pathname === '/market' || window.location.pathname === '/' ? '#49a3e4 !important' : 'white !important' }} >
                                        <li className="nav-item" onClick={() => this.selected_nav('market')}>
                                            <p style={{ marginTop: "16px", paddingRight: "17px", paddingLeft: "17px", color: this.state.active_tab === 'market' || window.location.pathname === '/market' || window.location.pathname === '/' ? '#49a3e4' : 'white' }} className="markets-tab" >Markets</p>
                                        </li>
                                    </Link>
                                    <li className="nav-item dropdown" onClick={() => this.selected_nav()} style={{ marginTop: "4px", paddingRight: "17px" }}>
                                        <a className={this.state.active_tab === 'us-equities' || window.location.pathname === '/us-equities' ||
                                            this.state.active_tab === 'global-indices' || window.location.pathname === '/global-indices' ||
                                            this.state.active_tab === 'currencies' || window.location.pathname === '/currencies'
                                            ? "nav-link dropdown-toggle my-space blue_highlight" : "nav-link dropdown-toggle my-space"} href="#" id="analytics_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Analytics</a>
                                        <div className="custom-dropdown dropdown-menu from-right z-index-menu" aria-labelledby="analytics_dropdown" style={{ width: '200px', left: 'auto' }}>
                                            <div className="arrow-up" id="analytics-arrow" />
                                            <Link to="/us-equities" onClick={() => this.selected_nav('us-equities')} className="dropdown-item" style={{ color: this.state.active_tab === 'us-equities' ? '#49a3e4' : 'white' }}>
                                                <i className="fa fa-globe" aria-hidden="true" />
                                                <span className="drop-menu-item">US-Equities</span>
                                            </Link>
                                            <Link to="/global-indices" onClick={() => this.selected_nav('global-indices')} className="dropdown-item" style={{ color: this.state.active_tab === 'global-indices' ? '#49a3e4' : 'white' }}>
                                                <i className="fa fa-bar-chart" aria-hidden="true" />
                                                <span className="drop-menu-item">Global Indices</span>
                                            </Link>
                                            <Link to="/currencies" onClick={() => this.selected_nav('currencies')} className="dropdown-item" style={{ color: this.state.active_tab === 'currencies' ? '#49a3e4' : 'white' }}>
                                                <i className="fa fa-usd" aria-hidden="true" />
                                                <span className="drop-menu-item">Currencies</span>
                                            </Link>
                                        </div>
                                    </li>
                                    <Link to="/article">
                                        <li className="nav-item" onClick={() => this.selected_nav('article')}>
                                            <p style={{ marginTop: "16px", paddingRight: "17px", marginRight: "10px", color: this.state.active_tab === 'article' || window.location.pathname === '/article' ? '#49a3e4' : 'white' }} className="articles-tab " >Articles</p>
                                        </li>
                                    </Link>
                                    <Link to="/aboutus" className="aboutus">
                                        <li className="nav-item" onClick={() => this.selected_nav('aboutus')}>
                                            <p style={{ marginTop: "16px", marginLeft: "7px", paddingRight: "17px", color: this.state.active_tab === 'aboutus' || window.location.pathname === '/aboutus' ? '#49a3e4' : 'white' }} className="about-tab " >About Us</p>
                                        </li>
                                    </Link>
                                    <li className="nav-item dropdown" style={{ marginTop: "5px", paddingRight: "17px" }}>
                                        <a className={this.state.active_tab === 'myspace' || window.location.pathname === '/myspace' ? "nav-link dropdown-toggle my-space blue_highlight" : "nav-link dropdown-toggle my-space"} style={{ marginTop: "-1px" }} href="#" id="myspace_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">My Space</a>
                                        <div className="custom-dropdown dropdown-menu from-right z-index-menu" aria-labelledby="myspace_dropdown" style={{ width: '200px', marginLeft: '-68px' }}>
                                            <div className="arrow-up" id="analytics-arrow" />
                                            <Link to="/myspace" onClick={() => this.selected_nav('myspace')} className="dropdown-item my-scratchpad" style={{ color: this.state.active_tab === 'myspace' ? '#49a3e4' : 'white' }}>
                                                <i className="fa fa-address-card" aria-hidden="true" /><span className="drop-menu-item">My ScratchPad</span>
                                            </Link>
                                            <a className="dropdown-item my-portfolio" ><i className="fa fa-folder-open-o" aria-hidden="true" /><span id="header-portfolio" className="drop-menu-item">My Portfolio
                                                <i className="fa fa-lock portfolio-lock" aria-hidden="true" />
                                            </span></a>
                                        </div>
                                    </li>
                                    <Link to="/charts" onClick={() => this.selected_nav('charts')} >
                                        <li className="nav-item">
                                            <p style={{ marginTop: "16px", paddingRight: "18px", color: this.state.active_tab === 'charts' || window.location.pathname === '/charts' ? '#49a3e4' : 'white' }} itemProp="url" className="chart-tab" >Charts</p>
                                        </li>
                                    </Link>
                                </ul>
                                {
                                    this.state.login_email === null ?
                                    <ul className="nav navbar-nav navbar-nav-cust-signin right-nav" style={{ position: 'absolute', right: 0, top: 0 }}>
                                        <li className="nav-item button-register" style={{ height: "44px" }}>
                                            <a className="nav-link signup-popup-link" style={{ paddingTop: "8px" }}>Register</a>
                                        </li>
                                        <li className="nav-item button-sign-in" onClick={this.show_login_modal} style={{ height: "44px" }}>
                                            <a className="nav-link signin-popup-link" style={{ paddingTop: "8px" }}>Sign In</a>
                                        </li>
                                    </ul>
                                    :
                                    <ul className="nav navbar-nav navbar-nav-cust-signin right-nav" style={{position: 'absolute', right: 0, top: 0}}>
                                        <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle sign-in-dropdown" href="#" id="user_menu_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fa fa-user" />{this.state.login_email}</a>
                                        <div className="custom-dropdown dropdown-menu from-right z-index-menu" aria-labelledby="user_menu_dropdown">
                                            <div className="arrow-up" />
                                            <a className="dropdown-item preferences" href="https://www.marketanalyst.ai/preferences/data-download/standard_download"><i className="fa fa-cog" /><span className="drop-menu-item">Preferences</span></a>
                                            <a className="dropdown-item sign-out-menu"><i className="fa fa-sign-out" /> <span className="drop-menu-item" onClick={()=>{this.sign_out()}}>Sign Out</span></a>
                                        </div>
                                        </li>
                                    </ul> 
                                }

                                

                                {/* <ul className="nav navbar-nav navbar-nav-cust-signin right-nav" style={{position: 'absolute', right: 0, top: 0}}>
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle sign-in-dropdown" href="#" id="user_menu_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fa fa-user" />koratchiraag</a>
                                    <div className="custom-dropdown dropdown-menu from-right z-index-menu" aria-labelledby="user_menu_dropdown">
                                        <div className="arrow-up" />
                                        <a className="dropdown-item preferences" href="https://www.marketanalyst.ai/preferences/data-download/standard_download"><i className="fa fa-cog" /><span className="drop-menu-item">Preferences</span></a>
                                        <a className="dropdown-item sign-out-menu"><i className="fa fa-sign-out" /> <span className="drop-menu-item">Sign Out</span></a>
                                    </div>
                                    </li>
                                </ul> */}

                            </nav> :
                            null
                    }


                    {
                        this.state.show_sidebar === true && window.location.pathname !== '/aboutus' && window.location.pathname !== '/term-of-use' && window.location.pathname !== '/privacy-policy' && window.location.pathname !== '/service' ?
                            <div>
                                <div className="container-fluid" style={{ marginTop: "40px" }}>
                                    <div className="row news-email-box-row" >
                                        <div className="col-md-2 digital ml-3" style={{ marginRight: "-7px" }}>
                                        </div>
                                        <div className="col-md-7 trading-panel" style={{marginLeft:"3px"}}>
                                            <span className="for-example">For example: <a className="cheap" href="#">Cheap Stocks</a>, <a className="worst" href="#">Worst Performers</a>, <a className="best" href="#">Best Performers</a>, <a className="week_high" href="#">52 Week High</a></span>
                                            <div className="new-search-panel" style={{ marginTop: "-1px" }}>
                                                <input type="search" id="analytics-search-page" name="search" placeholder="Trading Ideas from 3000+ Analytics (Type min 3 chars)" style={{ width: "99%" }} />
                                                <button className="search_button search_button_header trading-search fa fa-search" style={{ right: "-4px" }} />
                                                <span className="extra-ajax-loader-analytics" style={{ display: 'none' }}>
                                                    <span className="extra-ajax-loader-wrapper">
                                                        <i className="fa fa-spinner fa-spin" id="analytics-spinner" />
                                                    </span>
                                                </span>
                                                <div className="analytics-search-result">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 quote-panel pl-3" style={{ marginLeft: "-4px" }}>
                                            <div className="mb-12">
                                                <span className="for-example">For example:
                                                <a className="aapl" href="#"> Apple Inc</a>,
                                                <a className="indu" href="#"> UTIL</a>,
                                                <a className="xal" href="#"> Aluminum</a>
                                                </span>
                                                <div className="new-search-panel-right">
                                                    <input type="search" id="assets-search-page" name="search" placeholder="Quote Lookup (Type min 3 chars)" />
                                                    <button className="search_button search_button_header quote-search fa fa-search" style={{right:"-8px"}} />
                                                    <span className="extra-ajax-loader-news" style={{ display: 'none' }}>
                                                        <span className="extra-ajax-loader-wrapper">
                                                            <i className="fa fa-spinner fa-spin" id="analytics-spinner" />
                                                        </span>
                                                    </span>
                                                    <div className="quote-search-result" />
                                                </div>
                                            </div>        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "14px" }}>
                                    <div className="col-sm-12 col-lg-2 col-sm-12-cust mt-0 pr-0 mb-2" id="collapse-left-column" style={{ marginLeft: "15px",marginRight: "6px" }}>
                                        <Sidebar />
                                    </div>
                                    <div className="col-md-7" >
                                        <Route path="/charts">
                                            <Charts />
                                        </Route>
                                        <Route path='/myspace' >
                                            <Myspace />
                                        </Route>
                                        <Route path='/market'>
                                            <Market />
                                        </Route>
                                        <Route exact path='/'>
                                            <Market />
                                        </Route>
                                        <Route path="/article">
                                            <Article />
                                        </Route>
                                        <Route path="/us-equities">
                                            <Usequities />
                                        </Route>
                                        <Route path="/global-indices">
                                            <Globalindices />
                                        </Route>
                                        <Route path="/currencies">
                                            <Currencies />
                                        </Route>
                                        {

                                            ["/small-cap", "/large-cap", "/NASDAQ-most-active",
                                                "/automobiles-worst-performers", "/NASDAQ-52-week-high", "/small-cap-most-active-stocks"
                                                , "/FAANG-stocks", "/large-cap-best-daily-perfomance", "/SP500-cheap-stocks"
                                                , "/mid-cap-best-weekly-perfomance", "/large-cap-best-monthly-perfomance", "/NASDAQ-highest-dividend-yield"
                                                , "/SP500-best-daily-performers", "/SP500-highest-dividend-yield", "/SP500-best-yearly-perfomers"
                                                , "/tobacco-worst-perfomers", "/SP500-52-week-high", "/small-cap-cheap-stocks"
                                                , "/dow-30-stocks", "/warren-buffett-portfolio"
                                            ].map((path, index) =>
                                                <Route path={path} component={Screeners} key={index} />
                                            )
                                        }
                                    </div>
                                    <div className="col-sm-12 col-lg-3" id="collapse-right-column" style={{ marginTop: '0 !important', marginLeft: "-14px" }}>
                                        <div className="collapse-right-column mb-4 scratchpad-box">
                                            <div className="card-header" style={{ height: "43px" }}>
                                                <i className="fa fa-list card-title-icon custom-card-title" title="Show all tickers added in scratchpad" style={{ fontSize: "14px" }}>
                                                </i>
                                                <p style={{ marginTop: "-5px" }} className="card-title">Scratchpad <span className="card-title assets-card">(max of 30 tickers)</span></p>
                                                <i style={{ marginTop: "-31px", fontSize: "14px" }} className="fa fa-trash trash-scratchpad" title="Delete all tickers" aria-hidden="true" />
                                            </div>
                                            <div id="scrtachpad-container" className="cf-container card-block card-block-functions">
                                                <table cellSpacing={0} cellPadding={0} border={0} width="100%" className="scratchpad-table">
                                                    <thead className="scratch-pad-head-text">
                                                        <tr>
                                                            <th width="20%">Symbol</th>
                                                            <th width="30%">Last Price</th>
                                                            <th width="50%">Change</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="right-panel scratchpad-tbody">
                                                        <tr>
                                                            <td colSpan={3} className="no-data-right" style={{ fontSize: "14px", color: "#444444" }}>
                                                                Empty Scratchpad
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <button className="btn-save-portfolio agrud-button bottom-radius" style={{ height: "36px", paddingTop: "8px" }}>Save to Portfolio</button>
                                        </div>
                                        <div id="portfolio-box" className="collapse-right-column mb-4">
                                            <div className="card-header" style={{ height: "43px" }}>
                                                <i className="fa fa-list card-title-icon custom-card-title" title="Show all saved screen name" style={{ fontSize: "14px" }} />
                                                <p style={{ marginTop: "-5px" }} className="card-title">Portfolio <span className="card-title assets-card">(max of 30 tickers)</span></p>
                                                <i style={{ marginTop: "-31px", fontSize: "14px" }} className="fa fa-trash trash-portfolio" title="Delete all tickers" aria-hidden="true" />
                                            </div>
                                            <div id="portfolio-container" className="cf-container card-block card-block-functions">
                                                <span className="local-msg-box">
                                                <span className="local-msg-container">
                                                    <span className="local-msg-wrapper" style={{ marginLeft: "28px" }}>
                                                    <p
                                                        className="local-msg-content info_color_blue_bk"
                                                        style={{ height: "76px", marginLeft: "25px", width: "74%" }}
                                                    >
                                                        <span className="info-icon">
                                                        <i className="fa fa-info-circle" />
                                                        </span>
                                                        <span className="info-details">
                                                        <p className="local-msg-line-one" style={{height: "25px"}}>
                                                            Login is required to view your portfolio.
                                                        </p>
                                                        <span className="local-msg-footer">
                                                            <span className="msg-btn msg-btn-login">Sign In</span>
                                                        </span>
                                                        </span>
                                                    </p>
                                                    </span>
                                                </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            window.location.pathname === '/term-of-use' ?
                                <TermUse />
                                :
                                window.location.pathname === '/privacy-policy' ?
                                    <Privacy /> :
                                    window.location.pathname === '/service' ?
                                        <Service /> : <Aboutus />

                    }
                </Router>
                {/* {
                this.state.show_login_modal === true ? */}
                <div className="modal fade signin-popup signup-signin-popup" id="signin-bs-modal" tabIndex={-1} role="dialog" aria-labelledby="signinModalTitle">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="signin-box-left">
                                        <div className="box box-primary box-admin">
                                            <button type="button" className="signin-close close right-0 fa fa-close cancel_btn" onClick={this.close_login_modal} data-dismiss="modal" />
                                            <div className="box-header login-box-header padding-lr">
                                                <h3 className="box-title">Sign in</h3>
                                            </div>
                                            
                                            <form onSubmit={this.form.handleSubmit} className="login-form" >
                                            <div className="box-body padding-lr">
                                              
                                                <div className="form-group">
                                                    <input
                                                    type="text"
                                                    name="email"
                                                    onBlur={this.form.handleBlurEvent}
                                                    onChange={this.form.handleChangeEvent}
                                                    value={this.state.fields.email}
                                                    placeholder="Enter email"
                                                    className="form-control form-control-sm identity-email"
                                                    />
                                                </div>
                                               
                                                <div className="form-group">
                                                    <input
                                                    type="password"
                                                    name="password"
                                                    onBlur={this.form.handleBlurEvent}
                                                    onChange={this.form.handleChangeEvent}
                                                    value={this.state.fields.password}
                                                    placeholder="Enter password"
                                                    className="form-control form-control-sm identity-password"
                                                    />
                                                </div>
                                               
                                                <div className="form-group cust-form-group">
                                                        <a className="pull-right" href="/auth/forgot_password">Forgot password?</a>
                                                        <div className="checkbox">
                                                            <label htmlFor="remember"><input type="checkbox" name="remember" onChange={this.set_remember}
                                                            value={this.state.fields.remember} id="remember" />
                                                        Remember Me</label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label htmlFor="agree_terms">By signing in, you are agreeing with the <a href="https://www.marketanalyst.ai/terms">Terms of Use</a>, <a href="https://www.marketanalyst.ai/privacy">Privacy Policy</a> and the <a href="https://www.marketanalyst.ai/service">Terms of Service</a></label>
                                                        </div>
                                                    </div>
                                            </div>
                                            <p>
                                                <button type="submit" className="btn btn-primary btn-sm agrud-button sign-in-btn">Sign in</button>
                                            </p>
                                        </form>

                                            {/* <form className="login-form" method="post" acceptCharset="utf-8">
                                                <div className="box-body padding-lr">
                                                    <div className="info-msg" style={{ display: 'inline-block' }}>
                                                        <span className="login-success" style={{ display: 'none' }} />
                                                        <span className="login-error" style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" name="identity" defaultValue id="identity-si" className="form-control form-control-sm identity-email" placeholder="Enter email" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" defaultValue id="password-si" className="form-control form-control-sm identity-password" placeholder="Enter password" />
                                                    </div>
                                                    <div className="form-group cust-form-group">
                                                        <a className="pull-right" href="/auth/forgot_password">Forgot password?</a>
                                                        <div className="checkbox">
                                                            <label htmlFor="remember"><input type="checkbox" name="remember" defaultValue={1} id="remember" />
                                    Remember Me</label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label htmlFor="agree_terms">By signing in, you are agreeing with the <a href="https://www.marketanalyst.ai/terms">Terms of Use</a>, <a href="https://www.marketanalyst.ai/privacy">Privacy Policy</a> and the <a href="https://www.marketanalyst.ai/service">Terms of Service</a></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span className="extra-ajax-loader-signin" style={{ display: 'none' }}>
                                                    <span className="extra-ajax-loader-wrapper">
                                                        <i className="fa fa-spinner fa-spin signin-signup-ajax-icon" />
                                                    </span>
                                                </span>
                                                <input type="submit" name="submit" defaultValue="Sign in" className="btn btn-primary btn-sm agrud-button sign-in-btn" />
                                                <div className="checkbox">
                                                    <label htmlFor="signup_text">If you don't have an account, <a className="sign-up-anchor">Register </a>your account today! </label>
                                                </div>
                                            </form> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* :null
            } */}

                <div className="modal fade signup-popup signup-signin-popup" id="signup-bs-modal" tabIndex={-1} role="dialog" aria-labelledby="signupModalTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="signin-box-left">
                                        <div className="box box-primary box-admin">
                                            <button type="button" className=" close right-0 fa fa-close cancel_btn" data-dismiss="modal" />
                                            <div className="box-header login-box-header padding-lr">
                                                <h3 className="box-title">User Sign Up</h3>
                                            </div>
                                            {/* <form className="signup-form" method="post" acceptCharset="utf-8">
                                                <div className="box-body padding-lr">
                                                    <div className="info-msg" style={{ display: 'inline-block' }}>
                                                        <span className="login-success" style={{ display: 'none' }} />
                                                        <span className="login-error" style={{ display: 'none' }} />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6 form-group">
                                                            <input type="text" name="first_name" defaultValue id="first-name-su" className="form-control form-control-sm identity-first-name" placeholder="Enter first name" />
                                                        </div>
                                                        <div className="col-md-6 form-group">
                                                            <input type="text" name="last_name" defaultValue id="last-name-su" className="form-control form-control-sm identity-last-name" placeholder="Enter last name" />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" name="email" defaultValue id="email-su" className="form-control form-control-sm create_user" placeholder="Enter email" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" defaultValue id="password-su" className="form-control form-control-sm create_pswd" placeholder="Enter password" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password_confirm" defaultValue id="password-confirm-su" className="form-control form-control-sm create_pswd_confirm" placeholder="Confirm password" />
                                                    </div>
                                                </div>
                                                <span className="extra-ajax-loader-signin" style={{ display: 'none' }}>
                                                    <span className="extra-ajax-loader-wrapper">
                                                        <i className="fa fa-spinner fa-spin signin-signup-ajax-icon" />
                                                    </span>
                                                </span>
                                                <input type="submit" name="submit" defaultValue="Register" className="btn btn-primary btn-sm agrud-button sign-up-submit" />
                                                <div className="checkbox">
                                                    <label htmlFor="signup_text">Already have an account.<a className="sign-in-anchor"> Log in.</a></label>
                                                </div>
                                            </form> */}
                                            {
                                                this.state.first_name_error !== '' || this.state.last_name_error !== '' ||
                                                this.state.email_error !== '' || this.state.password_error !== '' ||
                                                this.state.password_confirm_error !== '' ?

                                                    <div className="col-md-10" style={{marginLeft:"26px"}}>
                                                    <span className="login-error" style={{display: 'block',fontSize:"14px",color:"#4c4c4c"}}>
                                                        <p>{this.state.first_name_error}</p>
                                                        <p>{this.state.last_name_error}</p>
                                                        <p>{this.state.email_error}</p>
                                                        <p>{this.state.password_error}</p>
                                                        <p>{this.state.password_confirm_error}</p>
                                                    </span>
                                                    </div>
                                            :null}
                                            <form  className="login-form" >
                                                <div className="box-body padding-lr">
                                                
                                                    <div className="form-group">
                                                        <input
                                                        type="text"
                                                        name="first_name"
                                                        // onBlur={this.form2.handleBlurEvent}
                                                        onChange={this.set_firstname}
                                                        value={this.state.firstname}
                                                        placeholder="Enter first name"
                                                        className="form-control form-control-sm identity-first_name"
                                                        />
                                                    </div>
                                                
                                                    <div className="form-group">
                                                        <input
                                                        type="text"
                                                        name="last_name"
                                                        // onBlur={this.form2.handleBlurEvent}
                                                        onChange={this.set_lastname}
                                                        value={this.state.lastname}
                                                        placeholder="Enter last name"
                                                        className="form-control form-control-sm identity-last_name"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <input
                                                        type="text"
                                                        name="email"
                                                        // onBlur={this.form2.handleBlurEvent}
                                                        onChange={this.set_email}
                                                        value={this.state.email}
                                                        placeholder="Enter email"
                                                        className="form-control form-control-sm identity-email"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <input
                                                        type="password"
                                                        name="password"
                                                        // onBlur={this.form2.handleBlurEvent}
                                                        onChange={this.set_password}
                                                        value={this.state.password}
                                                        placeholder="Enter password"
                                                        className="form-control form-control-sm identity-password"
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <input
                                                        type="password"
                                                        name="password_confirm"
                                                        // onBlur={this.form2.handleBlurEvent}
                                                        onChange={this.set_password_confirm}
                                                        value={this.state.password_confirm}
                                                        placeholder="Enter password confirm"
                                                        className="form-control form-control-sm identity-password_confirm"
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                <p>
                                                    <button type="button" onClick={()=>{this.register()}} className="btn btn-primary btn-sm agrud-button sign-in-btn">Register</button>
                                                </p>
                                                <div className="checkbox">
                                                    <label htmlFor="signup_text">Already have an account.<a className="sign-in-anchor"> Log in.</a></label>
                                                </div>
                                            </form>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
            </div>
        );
    }
}

export default Header;
