import React, { Component } from 'react';
import Header from './component/container/Header'
import Footer from './component/container/Footer'
import { BrowserRouter as Router,Route,Link } from 'react-router-dom';
class App extends Component {
    render() {
      return (
        <div>
           <Router >
          <Header />
          <Footer />
          </Router>
        </div>
      );
    }
}

export default App;